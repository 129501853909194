import React from 'react';
import { Link } from 'react-router-dom';
// import ContactUs from './ContactUs';

const Footer = () => (
  <footer className="pt-4 my-md-5 pt-md-5 border-top sticky-footer">
    <div className="container">
      <div className="row">
        <div className="col-6 col-md">
          <img className="mb-2" src="/logo192.png" alt="Direct Print Client logo" width="50" />
          {' '}
          Direct Print
          <small className="d-block mb-3 text-muted">{`© ${(new Date()).getFullYear()}`}</small>
        </div>
        <div className="col-12 col-md">
          <h5>Information</h5>
          <ul className="list-unstyled text-small">
            <li>
              <Link to="/plans" className="text-muted">Plans</Link>
            </li>
            <li>
              <Link to="/docs" className="text-muted">Docs</Link>
            </li>
            <li>
              <Link to="/faq" className="text-muted">FAQ</Link>
            </li>
          </ul>
        </div>
        <div className="col-12 col-md">
          <h5>About</h5>
          <ul className="list-unstyled text-small">
            {/* <li>
              <ContactUs btnClass="link-button text-muted" btnVariant="btn-link" />
            </li> */}
            <li>
              <Link to="/privacy-policy" className="text-muted">Privacy Policy</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
