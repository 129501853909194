import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Tab, Tabs } from 'react-bootstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { reducer, sendPrintnodeRequest } from '../utils';
import Loader from '../Components/Loader';

const History = () => (
  <>
    <h2 className="mt-4 mb-4">History</h2>

    <HistoryTable />

    <Alert variant="info">
      <Alert.Heading>History</Alert.Heading>
      <p>
        The Print History table shows your recent prints and useful information about them,
        including the print job id and title, the computer the job was sent to, the printer
        the job was sent to and the current state of the print job.
      </p>
      <p>
        You can see more information about each print job by expanding the row.
        The State History tab shows the progress of the job as it was executed.
        This is the first place you should look when troubleshooting a failed print job.
        The Info and Options tab shows detailed information about the print job as
        it was submitted to VentorPrint.
      </p>
    </Alert>
  </>
);

const HistoryTable = () => {
  const [page, setPage] = React.useState(1);
  const [pageItems, setPageItems] = React.useState([]);

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isLoading: false, isError: false,
    },
  );

  const handleFetchHistory = React.useCallback(async () => {
    dispatch({ type: 'FETCH_INIT' });

    sendPrintnodeRequest('printjobs?limit=250&dir=desc')
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong. Try to update page' });
        } else {
          const data = await response.json();
          dispatch({ type: 'FETCH_SUCCESS', payload: data });
        }
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: error.message });
      });
  }, []);

  React.useEffect(() => {
    handleFetchHistory();
  }, [handleFetchHistory]);

  React.useEffect(() => {
    setPageItems(state.data.slice(10 * (page - 1), 10 * page));
  }, [page, state.data]);

  if (state.isError) {
    return (
      <Alert variant="danger">
        <Alert.Heading>Oh snap!</Alert.Heading>
        {state.error || 'Something went wrong. Try again later'}
      </Alert>
    );
  }

  if (state.isLoading) {
    return (
      <Loader />
    );
  }

  return (
    <>
      {pageItems.length === 0 && (<Alert variant="warning">You have no printing history in your account</Alert>)}

      {pageItems.length > 0 && (
        <>
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Title (Print Job ID)</th>
                <th>Computer</th>
                <th>Printer</th>
                <th>State</th>
                <th>Created</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {pageItems.map((item) => <HistoryTableRow key={item.id} item={item} />)}
            </tbody>
          </table>

          <div className="mt-4 mb-4 d-flex justify-content-center align-items-center">
            <Button variant="outline-warning" size="sm" className="mr-2" onClick={() => setPage(1)} disabled={page === 1}>
              &lt;&lt; First
            </Button>

            <Button
              variant="outline-warning"
              size="sm"
              onClick={() => setPage(page - 1)}
              disabled={page === 1}
            >
              &larr; Previous
            </Button>

            <span className="mr-3 ml-3 align-middle">
              Page
              {' '}
              {page}
            </span>

            <Button
              variant="outline-warning"
              size="sm"
              onClick={() => setPage(page + 1)}
              disabled={page === Math.ceil(state.data.length / 10)}
            >
              Next &rarr;
            </Button>
          </div>
        </>
      )}
    </>
  );
};

const HistoryTableRow = ({ item }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [details, setDetails] = React.useState([]);

  const handleMoreButtonClick = (e) => {
    e.preventDefault();
    setIsExpanded(!isExpanded);
  };

  React.useEffect(() => {
    if (isExpanded) {
      // Load more data from PrintNode API
      sendPrintnodeRequest(`printjobs/${item.id}/states`)
        .then(async (response) => {
          if (response.status === 200) {
            const data = await response.json();
            setDetails(data[0] || []);
          } else {
            // eslint-disable-next-line no-alert
            alert('Something went wrong. Try to update page');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
          // eslint-disable-next-line no-alert
          alert('Something went wrong. Try to update page');
        });
    }
  }, [isExpanded]);

  return (
    <>
      <tr>
        <td>
          {item.title}
          {' '}
          (
          {item.id}
          )
        </td>
        <td>{item.printer.computer.name}</td>
        <td>{item.printer.name}</td>
        <td>
          {
        item.state === 'done'
          ? (
            <span className="badge bg-success text-white">
              <FontAwesomeIcon icon="check-circle" className="mr-1" />
              {item.state}
            </span>
          )
          : (
            <span className="badge bg-warning">
              <FontAwesomeIcon icon="exclamation-circle" className="mr-1" />
              {item.state}
            </span>
          )
      }

        </td>
        <td>{moment(item.createTimestamp).fromNow()}</td>
        <td>
          <Button variant="orange" size="sm" onClick={handleMoreButtonClick}>
            {
              isExpanded
                ? (
                  <>
                    <FontAwesomeIcon icon="arrow-up" className="mr-1" />
                    Less
                  </>
                )
                : (
                  <>
                    <FontAwesomeIcon icon="arrow-down" className="mr-1" />
                    More
                  </>
                )
            }
          </Button>
        </td>
      </tr>

      {
        isExpanded && (
          <tr className="no-hover">
            <td colSpan="6" className="pt-4 pb-4">
              <JobDetails job={item} details={details} />
            </td>
          </tr>
        )
      }
    </>
  );
};

HistoryTableRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    printer: PropTypes.shape({
      computer: PropTypes.shape({ name: PropTypes.string.isRequired }).isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    state: PropTypes.string.isRequired,
    createTimestamp: PropTypes.string.isRequired,
  }).isRequired,
};

const JobDetails = ({ job, details }) => {
  // // Do preprocessing here
  const data = details.map((item, i) => {
    let timeInState = 1;
    if (i < details.length - 1) {
      timeInState = details[i + 1].age - details[i].age;

      if (timeInState === 0) {
        timeInState = '< 1 ms';
      } else {
        timeInState = timeInState < 1000 ? `${timeInState} ms` : `${Math.round(timeInState / 1000)} s`;
      }
    } else {
      timeInState = 'n/a';
    }

    let age;
    if (item.age === 0) {
      age = '< 1 ms';
    } else {
      age = item.age < 1000 ? `${item.age} ms` : `${Math.round(item.age / 1000)} s`;
    }

    return {
      ...item,
      age,
      timeInState,
    };
  });

  return (
    <Tabs
      defaultActiveKey="history"
      id="job-details-tabs"
      className="mb-3"
    >
      <Tab eventKey="history" title="State History">
        <table className="table-sm small w-100">
          <thead>
            <tr>
              <th>State</th>
              <th style={{ width: '35%' }}>Description</th>
              <th>Client Version</th>
              <th>Total Time</th>
              <th>Time in State</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <tr key={i}>
                <td>{item.state}</td>
                <td>{item.message}</td>
                <td>{item.clientVersion || 'n/a'}</td>
                <td>{item.age}</td>
                <td>{item.timeInState}</td>
                <td>
                  {
                    // Format as January 12, 2023 19:34:03
                    moment(item.createTimestamp).format('MMMM D YYYY, H:mm:ss')
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Tab>
      <Tab eventKey="info" title="Info & Options">
        <table className="table-sm small w-100">
          <tbody>
            <tr>
              <td>State</td>
              <td>
                {
                  job.state === 'done'
                    ? (
                      <span className="badge bg-success text-white">
                        <FontAwesomeIcon icon="check-circle" className="mr-1" />
                        {job.state}
                      </span>
                    )
                    : (
                      <span className="badge bg-warning">
                        <FontAwesomeIcon icon="exclamation-circle" className="mr-1" />
                        {job.state}
                      </span>
                    )
                }
              </td>
            </tr>
            <tr>
              <td>Job ID</td>
              <td>{job.id}</td>
            </tr>
            <tr>
              <td>Job Title</td>
              <td>{job.title}</td>
            </tr>
            <tr>
              <td>Computer</td>
              <td>
                {`${job.printer.computer.name} / ${job.printer.computer.id}`}
              </td>
            </tr>
            <tr>
              <td>Printer</td>
              <td>
                {`${job.printer.name} / ${job.printer.id}`}
              </td>
            </tr>
            <tr>
              <td>Source</td>
              <td>
                {job.source}
              </td>
            </tr>
            <tr>
              <td>Document Type</td>
              <td>
                {job.contentType === 'raw_uri' ? 'RAW' : 'PDF'}
              </td>
            </tr>
            <tr>
              <td>Created At</td>
              <td>
                {moment(job.createTimestamp).format('MMMM D YYYY, H:mm:ss')}
              </td>
            </tr>
            <tr>
              <td>Expire At</td>
              <td>
                {moment(job.expireAt).format('MMMM D YYYY, H:mm:ss')}
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>
    </Tabs>
  );
};

JobDetails.propTypes = {
  job: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    printer: PropTypes.shape({
      id: PropTypes.number.isRequired,
      computer: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    source: PropTypes.string.isRequired,
    contentType: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    createTimestamp: PropTypes.string.isRequired,
    expireAt: PropTypes.string.isRequired,
  }).isRequired,
  details: PropTypes.arrayOf(PropTypes.shape({
    // id: PropTypes.number.isRequired,
    age: PropTypes.number.isRequired,
    state: PropTypes.string.isRequired,
    // timestamp: PropTypes.string.isRequired,
  })).isRequired,
};

export default History;
