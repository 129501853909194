import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Alert, Button, Card } from 'react-bootstrap';
import { reducer, sendRequest } from '../utils';

const PaymentMethods = () => {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], error: '', message: '', isLoading: false, isError: false,
    },
  );

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('payment-methods', 'GET')
      .then((response) => {
        if (response.status_code === 200) {
          dispatch({ type: 'FETCH_SUCCESS', payload: response.data });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error });
      });
  }, []);

  const handleDelete = (paymentMethodId) => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('payment-methods', 'DELETE', { payment_method_id: paymentMethodId })
      .then((response) => {
        if (response.status_code === 200) {
          const methods = state.data.filter((pm) => pm.id !== paymentMethodId);
          dispatch({ type: 'FETCH_SUCCESS', payload: methods });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error });
      });
  };

  const handleDefaultChange = (paymentMethodId) => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('payment-methods', 'PUT', { payment_method_id: paymentMethodId, is_default: true })
      .then((response) => {
        if (response.status_code === 200) {
          const methods = state.data;
          const changedMethod = methods.filter(
            (pm) => pm.id === Number.parseInt(paymentMethodId, 10),
          )[0];
          changedMethod.is_default = true;

          dispatch({ type: 'FETCH_SUCCESS', payload: methods });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error });
      });
  };

  return (
    <Card className="mb-4 flex-fill">
      <Card.Body>
        <h5>Payment Methods</h5>

        <Button
          variant="outline-warning"
          className="mt-2 mb-4"
          href="/profile/payment-methods/add"
        >
          Add new Payment Method
        </Button>

        {state.message && <Alert variant="success">{state.message}</Alert>}
        {state.error && <Alert variant="danger">{state.error}</Alert>}

        {
          state.data.length
            ? (
              <div className="table-responsive">
                <table className="table text-center">
                  <thead>
                    <tr>
                      <th>Is Default</th>
                      <th>Is Valid</th>
                      <th>Card</th>
                      <th>Date Added</th>
                      <th>Expiration Date</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      state.data.map(
                        (i) => (
                          <PaymentMethod
                            key={i.id}
                            data={i}
                            handleDelete={handleDelete}
                            handleDefaultChange={handleDefaultChange}
                          />
                        ),
                      )
                    }
                  </tbody>
                </table>
              </div>
            )
            : (
              <Alert variant="warning">
                You have no payment methods
              </Alert>
            )
        }

        <Alert variant="warning">
          <p>
            The default payment method will be used to charge for all of current subscriptions
            or next purchases.
          </p>
          <p className="mb-0">
            Changing default payment method will update existing subscriptions.
          </p>
        </Alert>

      </Card.Body>
    </Card>
  );
};

const PaymentMethod = ({ data, handleDelete, handleDefaultChange }) => (
  <tr>
    <td>
      <div className="form-check">
        <input
          disabled={!data.credit_card.is_valid}
          className="form-check-input"
          type="radio"
          name="default-method"
          defaultChecked={data.is_default}
          defaultValue={data.id}
          onChange={(e) => handleDefaultChange(e.target.value)}
        />
      </div>
      {data.is_default}
    </td>
    <td>
      {
        data.credit_card.is_valid ? (
          <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
        ) : (
          <FontAwesomeIcon icon={faMinusCircle} className="text-danger" />
        )
      }
    </td>
    <td className="text-nowrap">{`**** **** **** ${data.credit_card.last_numbers}`}</td>
    <td className="text-nowrap">
      {moment(data.date_created, 'YYYY-MM-DD hh:mm:ss').format('MMMM D, YYYY')}
    </td>
    <td className="text-nowrap">
      {moment(`${data.credit_card.exp_year}-${data.credit_card.exp_month}`, 'YYYY-M').format('MMMM, YYYY')}
    </td>
    <td>
      <Button
        rel="noreferrer"
        variant="outline-warning"
        onClick={() => handleDelete(data.id)}
      >
        <FontAwesomeIcon aria-label="Delete" icon="trash" />
      </Button>
    </td>
  </tr>
);

PaymentMethod.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    is_default: PropTypes.bool.isRequired,
    credit_card: PropTypes.shape({
      is_valid: PropTypes.bool.isRequired,
      last_numbers: PropTypes.string.isRequired,
      exp_month: PropTypes.number.isRequired,
      exp_year: PropTypes.number.isRequired,
    }).isRequired,
    date_created: PropTypes.string.isRequired,
  }).isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleDefaultChange: PropTypes.func.isRequired,
};

export default PaymentMethods;
