import { Alert, Card } from 'react-bootstrap';
import Plot from 'react-plotly.js';
import React from 'react';

import Loader from '../Components/Loader';
import { reducer, sendRequest } from '../utils';

function calculateLinearRegression(x, y) {
  const lr = {};
  const n = y.length;
  let sumX = 0;
  let sumY = 0;
  let sumXY = 0;
  let sumXX = 0;
  let sumYY = 0;

  for (let i = 0; i < y.length; i += 1) {
    sumX += x[i];
    sumY += y[i];
    sumXY += (x[i] * y[i]);
    sumXX += (x[i] * x[i]);
    sumYY += (y[i] * y[i]);
  }

  lr.sl = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
  lr.off = (sumY - lr.sl * sumX) / n;
  // eslint-disable-next-line max-len
  lr.r2 = (n * sumXY - sumX * sumY) / Math.sqrt((n * sumXX - sumX * sumX) * (n * sumYY - sumY * sumY)) ** 2;

  return lr;
}

const calculateTrend = (x, y) => {
  if ((x.length !== y.length) || x.length < 2) return [[], []];

  const xIndexes = x.map((e, i) => i);

  const lr = calculateLinearRegression(xIndexes, y);

  const fitFrom = x[0];
  const fitTo = x[x.length - 1];

  return [
    [fitFrom, fitTo],
    [Math.round(lr.off), Math.round(lr.sl * (x.length - 1) + lr.off)],
  ];
};

const getPlotData = (data) => {
  const x = data.map((i) => (new Date(i.date_printed)).toLocaleDateString());
  const y = data.map((i) => i.count);

  // Calculate trends
  const [trendX, trendY] = calculateTrend(x, y);

  return [
    {
      x,
      y,
      type: 'scatter',
      mode: 'lines+markers',
      marker: { color: '#ffc107' },
      name: 'Usage',
    },
    {
      x: trendX,
      y: trendY,
      type: 'scatter',
      mode: 'lines+markers',
      line: { dash: 'dot' },
      marker: { color: '#ffc107' },
      name: 'Trendline',
    },
  ];
};

const UsagePlot = () => {
  const [totalPrinted, setTotalPrinted] = React.useState(0);
  const [period, setPeriod] = React.useState([]);
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: {}, message: '', error: '', isLoading: true, isError: false,
    },
  );

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('printnode-history', 'GET')
      .then((response) => {
        if (response.status_code !== 200) {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
          return;
        }

        const plotData = getPlotData(response.data);

        dispatch({ type: 'FETCH_SUCCESS', payload: plotData });

        // Aggregate total printed
        const total = response.data.reduce((acc, cur) => acc + cur.count, 0);
        setTotalPrinted(total);
        setPeriod([
          response.data[0]?.date_current_period_start || null,
          response.data[0]?.date_current_period_end || null,
        ]);
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error });
      });
  }, []);

  if (state.isLoading) {
    return <Loader />;
  }

  if (!totalPrinted) {
    return (
      <Card className="mb-4 flex-fill">
        <Card.Body>
          <Card.Title>Usage</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            You have not printed anything yet.
          </Card.Subtitle>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card className="mb-4 flex-fill">
      <Card.Body>
        <Card.Title>Usage</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">
          Printed in current period (
          {`${new Date(period[0]).toLocaleDateString()} - ${new Date(period[1]).toLocaleDateString()}`}
          ):
          {' '}
          {totalPrinted}
        </Card.Subtitle>
        {
        state.isError
          ? (
            <Alert variant="warning">
              {state.error}
            </Alert>
          )
          : (
            <Plot
              data={state.data}
              style={{ width: '100%', height: '250px' }}
              layout={{
                legend: { x: 0, y: 1 },
                margin: {
                  l: 50, r: 0, b: 100, t: 0, pad: 0,
                },
                autosize: true,
                xaxis: {
                  title: 'Date (UTC)',
                  fixedrange: true,
                },
                yaxis: {
                  title: 'Printed Documents',
                  fixedrange: true,
                },
              }}
              config={{
                displayModeBar: false,
              }}
            />
          )
        }
      </Card.Body>
    </Card>
  );
};

export default UsagePlot;
