import { sendRequest } from '../utils';

class Stripe {
  constructor({
    stripe, card, onSuccess, onError,
  }) {
    this.stripe = stripe;
    this.card = card;

    this.onSuccess = onSuccess;
    this.onError = onError;
  }

  clearCache = () => {
    localStorage.removeItem('latestInvoiceId');
    localStorage.removeItem('latestInvoicePaymentIntentStatus');
  }

  createPaymentMethodAndSubscribe = async ({
    billingDetails, planId, isPaymentRetry, invoiceId, countryCode, vatNumber, paymentMethodId,
  }) => {
    if (paymentMethodId) {
      this.createSubscription(
        paymentMethodId, planId, billingDetails, countryCode, vatNumber,
      );
    } else {
      this.stripe
        .createPaymentMethod({
          type: 'card',
          card: this.card,
          billing_details: billingDetails,
        })
        .then((result) => {
          if (result.error) {
            this.onError(result.error);
          } else if (isPaymentRetry) {
            // Update the payment method and retry invoice payment
            this.retryInvoiceWithNewPaymentMethod(
              result.paymentMethod.id,
              invoiceId,
              planId,
            );
          } else {
            // Create the subscription
            this.createSubscription(
              result.paymentMethod.id, planId, billingDetails, countryCode, vatNumber,
            );
          }
        });
    }
  }

  createPaymentMethod = async ({ billingDetails }) => {
    this.stripe
      .createPaymentMethod({
        type: 'card',
        card: this.card,
        billing_details: billingDetails,
      })
      .then((result) => {
        if (result.error) {
          this.onError(result.error);
        } else {
          // Successfully created
          this.onSuccess(result);
        }
      });
  }

  createSubscription = (paymentMethodId, planId, billingDetails, countryCode, vatNumber) => {
    const opts = {
      payment_method_id: paymentMethodId,
      plan_id: planId,
      full_name: billingDetails.name,
      email: billingDetails.email,
      country_code: countryCode,
      vat_number: vatNumber,
    };

    sendRequest('subscriptions', 'POST', opts)
      .then((result) => {
        if (result.error) {
          throw result.message;
        }
        return result;
      })
      .then((result) => ({
        subscription: result.data,
        paymentMethodId,
        planId,
      }))
      .then(this.handleCardSetupRequired)
      .then(this.handlePaymentThatRequiresCustomerAction)
      .then(this.handleRequiresPaymentMethod)
      .then(this.onSubscriptionComplete)
      .catch((error) => {
        this.onError(error.error || error);
      });
  }

  onSubscriptionComplete = (result) => {
    this.clearCache();

    this.onSuccess(result);
  }

  retryInvoiceWithNewPaymentMethod = (paymentMethodId, invoiceId, planId) => (
    sendRequest('invoices', 'GET', { invoice_id: invoiceId, stripe_only: true })
      .then((result) => {
        if (result.error) {
          throw result;
        }
        return result.data;
      })
      .then((result) => ({
        invoice: result,
        paymentMethodId,
        plan_id: planId,
        isRetry: true,
      }))
      .then(this.handlePaymentThatRequiresCustomerAction)
      .then(this.onSubscriptionComplete)
      .catch((error) => {
        // The first one - API errors, the second - Stripe errors
        this.onError(error.message || error.error);
      })
  )

  handleCardSetupRequired = ({
    subscription, invoice, planId, paymentMethodId,
  }) => {
    const setupIntent = subscription.pending_setup_intent;

    if (setupIntent && setupIntent.status === 'requires_action') {
      return this.stripe
        .confirmCardSetup(setupIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            // start code flow to handle updating the payment details
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            throw result;
          } else if (result.setupIntent.status === 'succeeded') {
            // There's a risk of the customer closing the window before callback
            // execution. To handle this case, set up a webhook endpoint and
            // listen to setup_intent.succeeded.
            return {
              planId,
              subscription,
              invoice,
              paymentMethodId,
            };
          }
          return result;
        });
    }
    // No customer action needed
    return {
      subscription,
      planId,
      paymentMethodId,
    };
  }

  handlePaymentThatRequiresCustomerAction = ({
    subscription, invoice, planId, paymentMethodId, isRetry,
  }) => {
    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    const paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent;

    if (!paymentIntent) {
      return {
        subscription,
        planId,
        paymentMethodId,
      };
    }

    if (
      paymentIntent.status === 'requires_action'
      || (isRetry === true && paymentIntent.status === 'requires_payment_method')
    ) {
      return this.stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            // start code flow to handle updating the payment details
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            throw result;
          } else if (result.paymentIntent.status === 'succeeded') {
            // There's a risk of the customer closing the window before callback
            // execution. To handle this case, set up a webhook endpoint and
            // listen to invoice.paid. This webhook endpoint returns an Invoice.
            return {
              planId,
              subscription,
              invoice,
              paymentMethodId,
            };
          }
          return result;
        });
    }
    // No customer action needed
    return {
      subscription,
      planId,
      paymentMethodId,
    };
  }

  handleRequiresPaymentMethod = ({ subscription, paymentMethodId, planId }) => {
    if (subscription.status === 'active') {
      // subscription is active, no customer actions required.
      return {
        subscription,
        planId,
        paymentMethodId,
      };
    }

    if (subscription.latest_invoice.payment_intent.status === 'requires_payment_method') {
      // Using localStorage to store the state of the retry here
      // (feel free to replace with what you prefer)
      // Store the latest invoice ID and status
      localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
      localStorage.setItem(
        'latestInvoicePaymentIntentStatus',
        subscription.latest_invoice.payment_intent.status,
      );
      throw Error('Your card was declined.');
    } else {
      return {
        subscription,
        planId,
        paymentMethodId,
      };
    }
  }
}

export default Stripe;
