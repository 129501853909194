import React from 'react';
import {
  Alert, Button, Col, Form,
} from 'react-bootstrap';
import { useHistory } from 'react-router';
import {
  getCurrentUser, reducer, sendRequest,
} from '../utils';
import Loader from '../Components/Loader';
import CountryField from '../Components/CountryField';

const ProfileEdit = () => {
  const history = useHistory();
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: {}, message: '', error: '', isLoading: true, isError: false,
    },
  );

  const [isCompany, setIsCompany] = React.useState(false);

  const handleFetchProfile = () => {
    dispatch({ type: 'FETCH_INIT' });

    getCurrentUser()
      .then((user) => {
        dispatch({ type: 'FETCH_SUCCESS', payload: user });
        setIsCompany(user.is_company);
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error });
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch({ type: 'FETCH_INIT' });

    const opts = Object.fromEntries(new FormData(e.target));

    opts.is_company = opts.is_company === 'on';

    sendRequest('accounts', 'PUT', opts)
      .then((response) => {
        if (response.status_code === 200) {
          dispatch({
            type: 'FETCH_SUCCESS',
            message: 'The changes have been successfully saved',
            payload: response.data,
          });
          history.goBack();
        } else {
          dispatch({ type: 'FETCH_FAILURE', message: response.message });
        }
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error });
      });
  };

  React.useEffect(() => {
    handleFetchProfile();
  }, []);

  if (state.isLoading) {
    return <Loader />;
  }

  if (state.isError) {
    return <Alert variant="danger">Something went wrong...</Alert>;
  }

  return (
    <>
      <h4 className="mb-4">Edit profile</h4>
      {state.message && <Alert variant="success">{state.message}</Alert>}
      {state.error && <Alert variant="danger">{state.error}</Alert>}

      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control
            disabled
            id="email"
            name="email"
            type="email"
            placeholder="Email"
            value={state.data.email}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">Email can&apos;t be empty</Form.Control.Feedback>
        </Form.Group>

        <Form.Row>
          <Form.Group as={Col} md="6">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              required
              id="first_name"
              name="first_name"
              type="text"
              placeholder="First Name"
              defaultValue={state.data.first_name}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">First name can&apos;t be empty</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} md="6">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              required
              id="last_name"
              name="last_name"
              type="text"
              placeholder="Last Name"
              defaultValue={state.data.last_name}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Last Name can&apos;t be empty</Form.Control.Feedback>
          </Form.Group>
        </Form.Row>

        <hr className="mt-4" />

        <Form.Row>
          <Form.Group as={Col} md="2">
            <Form.Check
              type="checkbox"
              id="is_company"
              name="is_company"
              label="Is Company?"
              checked={isCompany}
              onChange={() => setIsCompany(!isCompany)}
              disabled={state.data.subscription && state.data.subscription.status !== 'trialing'}
            />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md="6">
            <Form.Label>Company Name</Form.Label>
            <Form.Control
              id="company"
              name="company"
              type="text"
              placeholder="Company Name"
              defaultValue={state.data.company}
              disabled={!isCompany || (state.data.subscription && state.data.subscription.status !== 'trialing')}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid" />
          </Form.Group>

          <Form.Group as={Col} md="6">
            <Form.Label>VAT Number</Form.Label>
            <Form.Control
              id="vat"
              name="vat"
              type="text"
              placeholder="VAT Number"
              defaultValue={state.data.vat}
              disabled={!isCompany || (state.data.subscription && state.data.subscription.status !== 'trialing')}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid" />
          </Form.Group>
        </Form.Row>

        <hr className="mt-4" />

        <Form.Row>
          <Form.Group as={Col} md="4">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              id="phone"
              name="phone"
              type="text"
              placeholder="Phone"
              defaultValue={state.data.phone}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid" />
          </Form.Group>

          <Form.Group as={Col} md="4">
            <Form.Label>City</Form.Label>
            <Form.Control
              id="city"
              name="city"
              type="text"
              placeholder="City"
              defaultValue={state.data.city}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid" />
          </Form.Group>

          <Form.Group as={Col} md="4">
            <Form.Label>Street</Form.Label>
            <Form.Control
              id="address_line"
              name="address_line"
              type="text"
              placeholder="House number and street name"
              defaultValue={state.data.address_line}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid" />
          </Form.Group>

        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md="2">
            <Form.Label>Post Code</Form.Label>
            <Form.Control
              id="post_code"
              name="post_code"
              type="text"
              placeholder="Post Code"
              defaultValue={state.data.post_code}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid" />
          </Form.Group>

          <Form.Group as={Col} md="5">
            <CountryField
              label="Country"
              name="country"
              defaultCountry={state.data.country}
              required
              disabled={state.data.subscription && state.data.subscription.status !== 'trialing'}
            />
          </Form.Group>

          <Form.Group as={Col} md="5">
            <Form.Label>State</Form.Label>
            <Form.Control
              id="state"
              name="state"
              type="text"
              placeholder="State"
              defaultValue={state.data.state}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid" />
          </Form.Group>
        </Form.Row>

        <div>
          <small>
            <span className="text-muted">
              Some fields are disabled because you have an active subscription.
              If you want to change them, please drop use a message at
              {' '}
              <a href="mailto:support@ventor.tech">support@ventor.tech</a>
            </span>
          </small>
        </div>

        <Button className="mt-4" variant="warning" type="submit">Save</Button>
        {/* TODO: Add redirect to previous page? */}
        <Button variant="outline-secondary" href="/profile" className="ml-3 mt-4" type="submit">Cancel</Button>
      </Form>
    </>
  );
};

export default ProfileEdit;
