import React from 'react';
import { Alert, Badge, Button } from 'react-bootstrap';

import Loader from '../Components/Loader';
import { reducer, sendPrintnodeRequest } from '../utils';

const Devices = () => (
  <>
    <h2 className="mt-4 mb-4">Devices</h2>

    <DevicesTable />

    <Alert variant="info">
      <Alert.Heading>Devices</Alert.Heading>
      <p>
        This page shows you a list of the computers registered on your account and the devices
        (i.e. printers and scales) attached to them.
      </p>
      <p>
        Computers show up in this list even if they are not currently connected to VentorPrint.
        The devices for a disconnected computer are the devices that were attached to it when
        it was last connected to VentorPrint.
      </p>
    </Alert>
  </>
);

const DevicesTable = () => {
  const [state, dispatch] = React.useReducer(
    reducer,
    { data: [], message: '', error: '', isLoading: false, isError: false },
  );

  const handleFetchDevices = React.useCallback(async () => {
    dispatch({ type: 'FETCH_INIT' });

    sendPrintnodeRequest('devices')
      .then(async (response) => {
        if (response.status === 200) {
          const data = await response.json();

          const computers = data.computers?.data || [];
          const printers = data.printers?.data || [];

          computers.forEach((computer) => {
            const computerPrinters = printers.filter((p) => p.computerId === computer.computerId);
            // eslint-disable-next-line no-param-reassign
            computer.printers = computerPrinters;
            return computer;
          });

          dispatch({ type: 'FETCH_SUCCESS', payload: computers });
        } else if (response.status === 204) {
          dispatch({ type: 'FETCH_SUCCESS', payload: [] });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong. Try to update page' });
        }
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: error.message });
      });
  }, []);

  const handleRemoveComputer = React.useCallback(async (computerId) => {
    dispatch({ type: 'FETCH_INIT' });

    sendPrintnodeRequest(`computers/${computerId}`, 'DELETE')
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong. Try to update page' });
        } else {
          await response.json();

          // Filter out the computer that was removed
          const computers = state.data.filter((c) => c.computerId !== computerId);
          dispatch({ type: 'FETCH_SUCCESS', payload: computers });
        }
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: error.message });
      });
  }, [state.data]);

  React.useEffect(() => {
    handleFetchDevices();
  }, [handleFetchDevices]);

  if (state.isError) {
    return (
      <Alert variant="danger">
        <Alert.Heading>Oh snap!</Alert.Heading>
        {state.error || 'Something went wrong. Try again later'}
      </Alert>
    );
  }

  if (state.isLoading) {
    return (<Loader />);
  }

  if (state.data.length === 0) {
    return <Alert variant="warning">You have no connected devices to your account</Alert>;
  }

  return (
    <table className="table table-hover">
      <thead>
        <tr>
          <th>Computer</th>
          <th>Printers</th>
        </tr>
      </thead>
      <tbody>
        {state.data.map((computer) => (
          <tr key={computer.computerId}>
            <td>
              {computer.name}
              {' '}
              {
                computer.state === 'connected'
                  ? <Badge variant="primary">{computer.state}</Badge>
                  : <Badge variant="secondary">{computer.state}</Badge>
              }

              <br />

              <small className="text-muted">
                {computer.computerId}
              </small>

              <br />

              {/* Remove computer button */}
              {
                computer.state === 'disconnected' && (
                <Button
                  variant="outline-danger"
                  size="sm"
                  className="mt-2"
                  onClick={() => handleRemoveComputer(computer.computerId)}
                >
                  Remove computer
                </Button>
                )
              }
            </td>

            <td>
              <ul>
                {
                  computer.printers.map((p) => (
                    <li key={p.printerId}>
                      {`${p.name} - ${p.printerId}`}
                      {
                        p.state
                          ? <i className="bi bi-check-circle m-1" />
                          : <i className="bi bi-check-circle m-1" />
                      }

                      {p.isDefault && <span className="badge badge-primary mr-2">Default</span>}
                      {p.state === 'offline' && <span className="badge badge-danger">Offline</span>}
                    </li>
                  ))
                }
              </ul>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Devices;
