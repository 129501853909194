import React from 'react';

import { Alert } from 'react-bootstrap';
import { reducer, sendRequest, useAuth } from '../../utils';
import Loader from '../../Components/Loader';
import YearlyPlans from './YearlyPlans';
import MonthlyPlans from './MonthlyPlans';
import TrialPlans from './TrialPlans';

const PurchaseOrUpgradePlan = () => {
  const [logged] = useAuth();

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isLoading: false, isError: false,
    },
  );

  const [subscription, setSubscription] = React.useState(null);
  const [type, setType] = React.useState(new URLSearchParams(window.location.search).get('tab') || 'year');

  const handleFetchPlans = React.useCallback(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('plans', 'GET', {})
      .then((response) => {
        dispatch({
          type: 'FETCH_SUCCESS',
          payload: response.data,
        });
      })
      .catch(() => dispatch({ type: 'FETCH_FAILURE' }));
  }, []);

  const fetchCurrentSubscription = React.useCallback(() => {
    if (logged) {
      sendRequest('subscriptions', 'GET', { active_only: true })
        .then((response) => {
          if (response.status_code === 200) {
            setSubscription(response.data[0]);
          }
        })
        .catch(() => {
          // eslint-disable-next-line no-alert
          alert('Something went wrong. Please try again later');
        });
    }
  }, []);

  React.useEffect(() => {
    fetchCurrentSubscription();
    handleFetchPlans();
  }, [logged]);

  const onUpgradeClick = (plan) => {
    if (subscription && (subscription.status !== 'trialing' && subscription.payment_method_id === null)) {
      dispatch({
        type: 'FETCH_FAILURE',
        error: (
          'Looks like you purchased the subscription using direct payment link. '
          + 'Please contact support to change your current plan or to manage your subscription.'
        ),
      });
      return;
    }

    // eslint-disable-next-line no-alert
    const result = window.confirm('Do you want to change your subscription?');

    if (result) {
      dispatch({ type: 'FETCH_INIT' });

      sendRequest('subscriptions', 'PUT', { new_plan_id: plan.id })
        .then((response) => {
          if (response.status_code === 200) {
            dispatch({ type: 'FETCH_SUCCESS', message: response.message });
            setSubscription(response.data);
          } else if (response.status_code === 404) {
            dispatch({ type: 'FETCH_SUCCESS', message: 'You have no active subscription' });
          } else {
            dispatch({ type: 'FETCH_FAILURE', error: response.message });
          }
        })
        .catch((error) => {
          dispatch({ type: 'FETCH_FAILURE', error });
        });
    }
  };

  if (state.isLoading) {
    return <Loader />;
  }

  return (
    <>
      {
        state.isError && (
        <Alert variant="danger">
          <Alert.Heading>Oh snap!</Alert.Heading>
          <div>{state.error}</div>
        </Alert>
        )
      }

      {
        state.message && (
        <Alert variant="success">
          <Alert.Heading>Success!</Alert.Heading>
          <div>{state.message}</div>
        </Alert>
        )
      }

      <div className="lead mt-3 mb-5">
        <p>
          We know that different businesses have different printing needs. So, we offer
          three custom subscription plans: Lite, Standard, and Premium. Each plan comes
          with a set number of documents you can print, tailored to match the size and
          needs of your company.
        </p>
      </div>

      <div className="btn-group w-50 mb-4 d-flex w-50 mx-auto">
        <button
          type="button"
          className={type === 'year' ? 'btn btn-warning' : 'btn btn-outline-secondary'}
          onClick={() => setType('year')}
        >
          Yearly
        </button>
        <button
          type="button"
          className={type === 'month' ? 'btn btn-warning' : 'btn btn-outline-secondary'}
          onClick={() => setType('month')}
        >
          Monthly
        </button>
        <button
          type="button"
          className={type === 'trial' ? 'btn btn-info' : 'btn btn-outline-info'}
          onClick={() => setType('trial')}
        >
          Free Trial
        </button>
      </div>

      {type === 'year' && (<YearlyPlans plans={state.data.filter((p) => p.period === type)} onSubscribe={subscription && subscription.status !== 'trialing' ? onUpgradeClick : null} />)}
      {type === 'month' && (<MonthlyPlans plans={state.data.filter((p) => p.period === type)} onSubscribe={subscription && subscription.status !== 'trialing' ? onUpgradeClick : null} />)}
      {type === 'trial' && (<TrialPlans plans={state.data.filter((p) => p.period === type)} />)}

      <div className="row">
        <div className="callout callout-info">
          <h4>What is a print?</h4>
          <p>
            A print is a single request to print accepted by our API. Prints are counted
            the same regardless of size or the number of pages printed. The number of prints
            included in your plan covers a billing period and it resets at the start of each
            billing period.
          </p>
        </div>
      </div>
    </>
  );
};

export default PurchaseOrUpgradePlan;
