import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { numberWithSpaces, useAuth } from '../../utils';

const Plan = ({ plan, onSubscribe, isPopular }) => {
  const [logged] = useAuth();

  // Prepare price value
  const price = (plan.price / (plan.period === 'year' ? 12 : 1)).toFixed(2);

  return (
    <div className="col-sm-4">
      {
        isPopular && (
          // Best Value
          <div className="most-popular-plan-bagde-wrapper rounded-top bg-warning">
            <div className="most-popular-plan p-2 text-center text-small">Most Popular</div>
          </div>
        )
      }
      <div className={`card mb-4 ${isPopular ? 'border-warning rounded-top-0 rounded-bottom' : ''}`}>
        <div className="card-header text-center">
          <h5 className="mb-0">{plan.name}</h5>
        </div>
        <div className="card-body">
          <ul className="list-group list-group-flush text-center">
            <li className="list-group-item">
              <div className="text-center">
                <span className="h1 font-weight-normal">
                  <sup>
                    <small>€</small>
                  </sup>
                  <span className="plan-price-cost">{price}</span>
                </span>
                <span className="ml-0">
                  / per month
                  {/* {' '} */}
                  {/* {plan.period} */}
                </span>

                {
                  plan.period === 'year' && (
                    <small className="d-block text-secondary font-size-1 ml-0 pt-2">
                      Billed annually
                    </small>
                  )
                }
              </div>
            </li>
            {plan.description && (
            <li className="list-group-item" style={{ whiteSpace: 'pre-wrap' }}>
              {plan.description}
            </li>
            )}
            <li className="list-group-item">
              {`${numberWithSpaces(plan.prints_included)} prints included per ${plan.period} *`}
            </li>
            <li className="list-group-item">
              {`€${plan.price_per_print} per extra print`}
            </li>
            <li className="list-group-item">Unlimited Printers</li>
          </ul>
          {!logged && <Link to="/login" className="btn btn-warning mt-4 w-100">Login to Subscribe</Link>}
          {logged && onSubscribe && <button type="button" onClick={() => onSubscribe(plan)} className={`btn ${isPopular ? 'btn-warning' : 'btn-outline-warning'} mt-4 mr-2 w-100`}>Subscribe Now</button>}
          {logged && !onSubscribe && <Link to={`/checkout/${plan.id}`} className={`btn ${isPopular ? 'btn-warning' : 'btn-outline-warning'} mt-4 mr-2 w-100`}>Subscribe Now</Link>}
        </div>
      </div>
    </div>
  );
};

Plan.propTypes = {
  plan: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    period: PropTypes.string.isRequired,
    prints_included: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
    price_per_print: PropTypes.number.isRequired,
  }).isRequired,
  onSubscribe: PropTypes.func,
  isPopular: PropTypes.bool,
};

Plan.defaultProps = {
  onSubscribe: null,
  isPopular: false,
};

export const ActivePlan = ({ plan }) => (
  <div className="col-sm-4">
    <div className="card text-white bg-dark mb-4">
      <div className="card-header text-center">
        <h5 className="mb-0">{plan.name}</h5>
      </div>
      <div className="card-body">
        <ul className="list-group list-group-flush text-center">
          <li className="list-group-item text-white bg-dark">
            <div className="text-center">
              <span className="display-4 font-weight-normal">
                <sup>
                  <small>€</small>
                </sup>
                <span className="plan-price-cost">{plan.price}</span>
              </span>
              <span className="d-block text-secondary font-size-1 ml-0">per month</span>
            </div>
          </li>
          {plan.description && (
          <li className="list-group-item text-white bg-dark" style={{ whiteSpace: 'pre-wrap' }}>
            {plan.description}
          </li>
          )}
          <li className="list-group-item text-white bg-dark">
            {`${numberWithSpaces(plan.prints_included)} prints included per ${plan.period} *`}
          </li>
          <li className="list-group-item text-white bg-dark">
            {`€${plan.price_per_print} per extra print`}
          </li>
          <li className="list-group-item text-white bg-dark">Unlimited Printers</li>
        </ul>
        <a href="#/" className="btn btn-outline-warning text-white mt-4 d-block disabled ">Current Plan</a>
      </div>
    </div>
  </div>
);

ActivePlan.propTypes = {
  plan: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    period: PropTypes.string.isRequired,
    prints_included: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
    price_per_print: PropTypes.number.isRequired,
  }).isRequired,
};

export const TrialPlan = ({ plan, onStartFreeTrial }) => {
  const [logged] = useAuth();

  const handleStartFreeTrial = () => {
    onStartFreeTrial(plan.id);
  };

  return (
    <div className="col-sm-4 offset-sm-4">
      <div className="card mb-4 border-info">
        <div className="card-header bg-info text-center">
          <h5 className="mb-0">{plan.name}</h5>
        </div>
        <div className="card-body">
          <ul className="list-group list-group-flush text-center">
            <li className="list-group-item">
              {plan.description}
            </li>
            <li className="list-group-item">
              {`${numberWithSpaces(plan.prints_included)} prints included per ${plan.period} *`}
            </li>
            <li className="list-group-item">
              No credit card required
            </li>
          </ul>
          {!logged && <Link to="/login" className="btn btn-info mt-4 w-100">Login to Start Free Trial</Link>}
          {logged && <button type="button" onClick={handleStartFreeTrial} className="btn btn-outline-info mt-4 w-100">Start Free Trial</button>}
        </div>
      </div>
    </div>
  );
};

TrialPlan.propTypes = {
  plan: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    period: PropTypes.string.isRequired,
    prints_included: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
    price_per_print: PropTypes.number.isRequired,
  }).isRequired,
  onStartFreeTrial: PropTypes.func.isRequired,
};

export default Plan;
