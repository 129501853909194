import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { reducer, sendRequest } from '../utils';

const PaymentMethodField = ({ label, name, defaultPaymentMethod, onChange, required }) => {
  const [, setPaymentMethod] = React.useState(defaultPaymentMethod);

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isLoading: true, isError: false,
    },
  );

  const handleCountryFetch = () => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('payment-methods', 'GET')
      .then((response) => {
        dispatch({ type: 'FETCH_SUCCESS', payload: response.data });
      })
      .catch(() => {
        dispatch({
          type: 'FETCH_FAILURE',
          payload: 'Something wen wrong. Please, contact us: support@ventor.tech',
        });
      });
  };

  React.useEffect(() => {
    handleCountryFetch();
  }, []);

  const handleChange = (e) => {
    setPaymentMethod(e.target.value);

    onChange(e.target.value);
  };

  return (
    <>
      <label className="form-label" htmlFor={name}>
        {label}
      </label>
      {
      state.isLoading
        ? <p className="align-middle">Loading payment methods...</p>
        : (
          <select name={name} onChange={handleChange} value={defaultPaymentMethod} className="custom-select" required={required}>
            <option value="">Select payment method</option>
            {
            state.data.map(
              (pm) => (
                <option key={pm.stripe_id} value={pm.stripe_id}>
                  {`**** **** **** ${pm.credit_card.last_numbers}`}
                  {' '}
                  (
                  {moment(`${pm.credit_card.exp_year}-${pm.credit_card.exp_month}`, 'YYYY-M').format('MMMM, YYYY')}
                  )
                </option>
              ),
            )
          }
          </select>
        )
        }
    </>
  );
};

PaymentMethodField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultPaymentMethod: PropTypes.string,
  required: PropTypes.bool,
};

PaymentMethodField.defaultProps = {
  required: false,
  defaultPaymentMethod: '',
};

export default PaymentMethodField;
