import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import InputWithCopy from '../Components/InputWithCopy';

const SubscriptionAccount = ({ account }) => {
  if (!account) {
    return (
      <>
        <h5>Account</h5>
        <p>Something went wrong. Please contact us: support@ventor.tech</p>
      </>
    );
  }

  return (
    <Card className="mb-4 flex-fill">
      <Card.Body>
        <h5>Account</h5>
        <p>
          Login to the desktop client using these credentials:
        </p>

        <dl className="row mt-4">
          <dt className="col-sm-3">Username</dt>
          <dd className="col-sm-9">
            <InputWithCopy value={account.email} readonly />
          </dd>

          <dt className="col-sm-3">Password</dt>
          <dd className="col-sm-9">
            <InputWithCopy value={account.password} readonly />
          </dd>

          <dt className="col-sm-3">API Key</dt>
          <dd className="col-sm-9">
            <InputWithCopy value={account.api_key?.key} readonly />
          </dd>
        </dl>
      </Card.Body>
    </Card>
  );
};

SubscriptionAccount.propTypes = {
  account: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
    api_key: PropTypes.shape({
      key: PropTypes.string.isRequired,
    }),
    api_keys: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        key: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }),
    ),
  }),
};

SubscriptionAccount.defaultProps = {
  account: null,
};

export default SubscriptionAccount;
