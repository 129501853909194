import PropTypes from 'prop-types';
import React from 'react';

const InputWithCopy = ({ value, readonly }) => {
  const [message, setMessage] = React.useState('');
  const inputRef = React.useRef(null);

  const onCopyButtonClick = (e) => {
    e.preventDefault();
    inputRef.current.select();
    document.execCommand('copy');
    setMessage('Copied!');
  };

  return (
    <div>
      <div className="input-group">
        <input
          type="text"
          className="form-control"
          defaultValue={value}
          readOnly={readonly}
          ref={inputRef}
        />
        <div className="input-group-append">
          <button type="button" className="btn btn-outline-warning" onClick={onCopyButtonClick}>Copy</button>
        </div>
      </div>
      <small>{message}</small>
    </div>
  );

  // <i class="large material-icons">insert_chart</i>
};

InputWithCopy.propTypes = {
  value: PropTypes.string,
  readonly: PropTypes.bool,
};

InputWithCopy.defaultProps = {
  value: '',
  readonly: false,
};

export default InputWithCopy;
