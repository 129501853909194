import React, { useEffect } from 'react';
import { useParams } from 'react-router';

const LoginAsCustomer = () => {
  // This view gets access_token from the URL params and set it to the localStorage.
  // After that it should redirect user to the home page.

  const { accessToken } = useParams();

  useEffect(() => {
    localStorage.setItem('REACT_TOKEN_AUTH_KEY', JSON.stringify({
      access_token: accessToken,
    }));
    window.location.href = '/';
  },
  // eslint-disable-next-line camelcase
  [accessToken]);

  return (
    <div>Loading...</div>
  );
};

export default LoginAsCustomer;
