import React from 'react';

const PrivacyPolicy = () => (
  <div>
    <div>
      <h1 className="">Privacy &amp; Policy</h1>
      <p className="lead">
        Last modified: October 13th, 2021
      </p>

      <div className="mt-4 mb-4">
        <h2>1. Introduction</h2>

        <p>
          1. We are committed to safeguarding the privacy of our website visitors
          (print.ventor.tech), users of all our products or when you interact with us in any form,
          including our mobile applications owned and controlled by VentorTech.
        </p>
        <p>
          2. This privacy policy applies where we are acting as a data controller with respect
          to the personal data of our website visitors and service users; in other words,
          where we determine the purposes and means of the processing of that personal data.
          Hence, it governs our data collection, processing and usage practices.
          It also describes your choices regarding use, access and correction of
          your personal information. By using our website, our applications and
          other products, you consent to the data practices described in this privacy policy.
          If you do not agree with the data practices described in this privacy policy,
          you should not use our website, our applications or other products.
        </p>
        <p>
          3. We may change this privacy policy from time to time. Whenever we make changes
          to this privacy policy, the changes are effective 30 days after we post
          the revised privacy policy (as indicated by revising the date at the top of
          our privacy policy). We encourage you to review our privacy policy whenever
          you access our services to stay informed about our information practices and
          the ways you can help protect your privacy.
        </p>
        <p>
          4. We will ask you to consent to our use of cookies in accordance with the terms of
          this policy when you first visit our website.
        </p>
        <p>
          5. In this policy, “we”, “us” and “our” refer to VentorTech. For more information
          about us, see Section 12.
        </p>
      </div>

      <div className="mb-4">
        <h2>2. How we use your personal data</h2>

        <p>
          1. This section explains what categories of data that we process, the sources
          we obtain data from, the purposes for which we process the personal data,
          how we share your personal data, how we allow for third-party analytics.
        </p>
        <p>
          2. We collect information you provide directly to us. For example, we collect information
          when you participate in any interactive features of our services, fill out a form,
          request customer support, provide any contact or identifying information or otherwise
          communicate with us. The types of information we may collect include your name,
          email address, profile picture, phone number, postal address, credit card information
          and other contact or identifying information you choose to provide. The legal basis
          for this processing is consent.
        </p>
        <p>
          3. When you access or use our services, we automatically collect information about you.
          The legal basis for this processing is our legitimate interests, namely monitoring and
          improving our website and services. The information we collect is including:
        </p>

        <ul>
          <li>
            Log Information: We log information about your use of our services, including the type
            of browser you use, access times, pages viewed, your IP address and the page you visited
            before navigating to our services.
          </li>
          <li>
            Device Information: We collect information about the computer you use to access
            our services, including the hardware model, and operating system and version.
          </li>
          <li>
            Location Information: We may collect information about the location of your device
            each time you access or use one of our mobile applications or otherwise consent
            to the collection of this information.
          </li>
          <li>
            Information Collected by Cookies and Other Tracking Technologies:
            We use various technologies to collect information, and this may include sending cookies
            to your computer. Cookies are small data files stored on your hard drive or
            in your device memory that helps us to improve our services and your experience,
            see which areas and features of our services are popular andcount visits.
            We may also collect information using web beacons (also known as “tracking pixels”).
            Web beacons are electronic images that may be used in our services or emails and
            to track count visits or understand usage and campaign effectiveness.
          </li>
        </ul>

        <p>
          4. In order to provide you with access to the Service, or to provide you with
          better service in general, we may combine information obtained from other sources
          (for example, a third party service whose application you have authorized or used
          to sign in) and combine that with information we collect through our services.
          The legal basis for this processing is consent.
        </p>

        <p>
          5. We use information about you for various purposes. The legal basis for this processing
          is our legitimate interests, namely monitoring and improving our website and services.
          The purposes is including:
        </p>

        <ul>
          <li>Provide, maintain and improve our services;</li>
          <li>
            Provide services you request, process transactions and to send you related information;

          </li>
          <li>
            Send you technical notices, updates, security alerts and support and
            administrative messages;
          </li>
          <li>Respond to your comments, questions and requests and provide customer service;</li>
          <li>Communicate with you about news and information related to our service;</li>
          <li>
            Monitor and analyze trends, usage and activities in connection with our services; and
          </li>
          <li>Personalize and improve our services.</li>
        </ul>

        <p>
          6. We may share personal information about you. The legal basis for this processing
          is our legitimate interests, namely monitoring and improving our website and services.
          The information we may share about you is as follows:
        </p>

        <ul>
          <li>
            With third party vendors and other service providers who need access to your information
            to carry out work on our behalf, such as economic etc.
          </li>
          <li>
            If we believe disclosure is reasonably necessary to comply with any applicable law,
            regulation, legal process or governmental request;
          </li>
          <li>
            To enforce applicable user agreements or policies, including our Terms of Service;
            and to protect us, our users or the public from harm or illegal activities;
          </li>
          <li>
            In connection with any merger, sale of VentureTech assets, financing or acquisition
            of all or a portion of our business to another company; and
          </li>
          <li>
            If we notify you through our services (or in our privacy policy) that
            the information you provide will be shared in a particular manner and you provide
            such information.
          </li>
          <li>
            We may also share aggregated or anonymized information that does not directly
            identify you.
          </li>
        </ul>

        <p>
          7. We may allow third parties to provide analytics services. These third parties may
          use cookies, web beacons and other technologies to collect information about your use
          of the services and other websites, including your IP address, web browser, pages viewed,
          time spent on pages, links clicked and conversion information. This information
          may be used by us and third parties to, among other things, analyze and track data,
          determine the popularity of certain content and other websites and better understand
          your online activity. The legal basis for this processing is our legitimate interests,
          namely monitoring and improving our website and services.
        </p>
      </div>

      <div className="mb-4">
        <h2>3. Providing your personal data to others</h2>

        <p>
          1. We may provide your personal data to other members of our group of companies
          if it&apos;s reasonable necessary.
        </p>
        <p>
          2. We may provide your personal data to our professional advisors for the purpose
          of obtaining advice etc., if it&apos;s reasonably necessary.
        </p>
        <p>
          3. We may provide your personal data to our suppliers and subcontractors if it&apos;s
          reasonably necessary.
        </p>
        <p>
          4. We may provide your personal data to be handled by our payment services providers
          for the purpose of processing your payment.
        </p>
      </div>

      <div className="mb-4">
        <h2>4. International transfer of your personal data</h2>

        <p>
          1. Our headquarters is located in Estonia. According to the European Commission
          free movement of data is not restricted or prohibited within EU/EEA. We have
          internal employees and contractors both inside EU and outside EU. To protect
          your personal data we have strict NDA in place with every employee signed with
          Estonian legal entity.
        </p>
        <p>
          2. Hosting of our website and application services are located in European countries.
          According to the European Commission free movement of data is not restricted or
          prohibited within EU/EEA.
        </p>
        <p>
          3. Our subcontractors and third-party service providers are located in
          the following countries, for which, according to the European Commission,
          an “adequacy decision” has been made with respect to this country and transfers of data
          to this country will be protected by appropriate safeguards.:
        </p>

        <ul>
          <li>Poland</li>
          <li>France</li>
          <li>US</li>
          <li>Germany</li>
          <li>Estonia</li>
          <li>Belgium</li>
        </ul>
      </div>

      <div className="mb-4">
        <h2>5. How long we will store your data</h2>

        <p>
          1. We will not keep personal data for longer than necessary for the purposes that
          we are storing the data.
        </p>
        <p>
          2. The personal data that we store to fulfill our obligations and services to visitors
          of our website will be retained for a minimum of one month and a maximum of 24 months.
          This includes the data that our visitors provide us with through signup forms,
          registration forms, contact forms and messages via email, phone or other means
          of communication.
        </p>
        <p>
          3. The personal data that we store to fulfill our obligations to users of
          our applications will be retained for as long as determined necessary. We will deem it
          necessary to retain the data if the data it is necessary for us to store to be able
          to provide and deliver the services that our users expect of us.
        </p>
        <p>
          4. However the provisions in section 5 we will once every 6 months evaluate the need
          to store and retain the personal data that we have.
        </p>
        <p>
          5. If we have retained personal data for more than 24 months and the personal data
          has not been used in any way or is not deemed necessary to keep in any way
          we will delete the data.
        </p>
      </div>

      <div className="mb-4">
        <h2>6. Security</h2>

        <p>
          1. We take reasonable measures to help protect personal information from loss,
          theft, misuse and unauthorized access, disclosure, alteration and destruction.
        </p>
        <p>
          2. Unfortunately, and despite all our efforts, no company or service can be guaranteed
          to be 100% secure.
        </p>
        <p>
          3. Even considering VentorTech security endeavors, our users also play an important role
          regarding the security of their personal data. The security measures applied on
          your assets or the assets of your company, as well as your behaviors when using
          these assets are crucial for maintaining personal data secure. Actions such as defining
          a weak password to access our services, not locking your laptop or mobile device when
          You left it unattended, or using public assets to login on our website, all pose a risk.
          This way, we ask you to take all the necessary precautions in order
          to avoid security breaches.
        </p>
      </div>

      <div className="mb-4">
        <h2>7. Cookies</h2>

        <p>
          1. Most web browsers are set to accept cookies by default. If you prefer,
          you can usually choose to set your browser to remove or reject browser cookies.
          Please note that if you choose to remove or reject cookies, this could affect
          the availability and functionality of our services.
        </p>
        <p>
          2. Cookies does not typically contain information that specifically identify
          a user personally, but it can store personal information about you and that information
          can be linked to any other information that are stored about you,
          if the information is contained from cookies.
        </p>
        <p>3. We use cookies in the following instances:</p>
        <ul>
          <li>
            To identify you as a visitor on our website with the purpose of providing you
            with relevant information.
          </li>
          <li>
            To determine if you are logged into our application with the purpose of providing
            our services to you through our application.=
          </li>
          <li>
            In security instances where cookies can help us determine if there is fraudulent
            use of login credentials and to protect our website and services provided through
            our applications.
          </li>
          <li>
            To identify you as a user that have previously interacted with us online and
            have opted-in to receive promotional communication from us with the purpose of providing
            you with relevant and necessary information.
          </li>
          <li>
            To analyze the use and performance of our website and applications with
            the purpose of being able to provide the best possible experience
            to our visitors and our users.
          </li>
          <li>
            To store the consent given to us from visitors and users of our website and applications
            with the purpose of providing you with the service and functionality that you request
            from us as a visitor or user.
          </li>
        </ul>
      </div>

      <div className="mb-4">
        <h2>8. Opt-in / Opt-out</h2>

        <p>
          1. If you sign up on our website to hear more about our services we will ensure that you
          have an easy to understand opt-in solution, where you can choose to opt-in
          to receive communication.
        </p>
        <p>
          2. In the opt-in form it will be clearly described for what purpose we will use the data
          that you provide us with.
        </p>
        <p>
          3. If you opt-in to receive communication from us we will make it easy for you to opt-out
          again by simple letting us know that you no longer wish to receive
          any further communication from us.
        </p>
        <p>
          4. If you choose to opt-out of receiving communication from us, and this will hinder us
          in providing you the service that you have requested from us, and/or paid for to us,
          we will let you know that we will no longer be able to provide you with our services.
        </p>
        <p>
          5. If you are a visitor of our website, and have chosen to opt-in to receive promotional
          emails from us, you will furthermore have the opportunity to opt-out of receiving
          any promotional emails from us by following the instructions in those emails that
          we send you with promotional emails.
        </p>
        <p>
          6. You may instruct us in not processing your personal data for any marketing purposes.
        </p>
      </div>

      <div className="mb-4">
        <h2>9. Your rights</h2>

        <p>
          1. You have the right to instruct us to provide you with the information that
          we have available in our possession about you.
        </p>
        <p>
          2. If permitted by law we may withhold personal information about you if you request us
          to provide you with all the information we have available about you.
        </p>

        <p>
          3. Under the data protection law you have the right of access as described here
          {' '}
          <a href="https://gdpr-info.eu/art-15-gdpr/">https://gdpr info.eu/art-15-gdpr/</a>
        </p>

        <p>
          4. Under the data protection law you have the right to rectification as described here
          <a href="https://gdpr-info.eu/art-16-gdpr/">https://gdpr-info.eu/art-16-gdpr/</a>

        </p>

        <p>
          5. Under the data protection law you have the right to erasure as described here
          <a href="https://gdpr-info.eu/art-17-gdpr/">https://gdpr-info.eu/art-17-gdpr/</a>

        </p>

        <p>
          6. Under the data protection law you have the right to restriction of
          processing as described here
          {' '}
          <a href="https://gdpr-info.eu/art-18-gdpr/">https://gdpr-info.eu/art-18-gdpr/</a>
        </p>

        <p>
          7. Under the data protection law you have the right to complain to a supervisory authority
          as described here
          {' '}
          <a href="https://gdpr-info.eu/art-19-gdpr/">https://gdpr-info.eu/art-19-gdpr/</a>
        </p>

        <p>
          8. Under the data protection law you have the right to data portability as described here
          <a href="https://gdpr-info.eu/art-20-gdpr/">https://gdpr-info.eu/art-20-gdpr/</a>

        </p>

        <p>
          9. Under the data protection law you have the right to object as described here
          {' '}
          <a href="https://gdpr-info.eu/art-21-gdpr/">https://gdpr-info.eu/art-21-gdpr/</a>

        </p>
      </div>

      <div className="mb-4">
        <h2>10. Data Retention</h2>

        <p>
          1. We will keep your personal information for two years from your last interaction
          with us, such as the date your account was deleted, your last order, the last time
          you used one of our apps, or in some other way contacted or interacted, unless a longer
          or shorter retention period is required by law, is necessary in the course of
          legal proceedings, or is otherwise needed for a particular purpose under applicable law.
        </p>
        <p>
          2. The following are examples of how VentorTech might keep your personal information
          for as long as necessary for a particular purpose:
        </p>

        <ul>
          <li>
            We may keep your personal information collected during a purchase of a Service for
            as long as required by tax laws (longer than two years in some European countries);
          </li>
          <li>
            We may keep your personal information for a shorter period if you ask us to delete
            your personal information. In such a case, VentorTech will aim to delete
            your personal information within a maximum period of one month
            from the date of the request;
          </li>
          <li>
            In general, we will hold your personal information on our systems for as long
            as is necessary for the relevant service, or as long as is set out in
            any relevant contract you hold with us and for a reasonable period of time afterward,
            for instance to pursue our legitimate business interests, conduct audits,
            comply with our legal obligations, resolve disputes, and enforce our agreements.
          </li>
        </ul>

        <p>3. We review our retention periods for personal information on a regular basis.</p>
      </div>

      <div className="mb-4">
        <h2>11. How you can access and update your information</h2>

        <p>
          1. If you have registered for an account with VentorTech, you may generally update
          your user settings, profile and organization’s settings by logging into the VentureTech
          websites or into the applicable service with your username and password and editing
          your settings or profile. To update your information, discontinue your account,
          and/or request return or deletion of your data associated with your account,
          please contact hello@ventor.tech. For other requests to access, correct, modify or
          delete Your personal information, please contact hello@ventor.tech.
        </p>
        <p>
          2. We will respond to your requests without undue delay, but within one month.
          If permitted under applicable legislation, VentorTech may extend the response period
          with two further months if such is necessary due to the complexity and number of requests.
          VentorTechwill notify you of any extension and the reason thereof. If VentorTech denies
          your request, we shall inform you on the reasons for such a denial.
        </p>
        <p>
          3. If information about you is processed by VentorTech on behalf of one of our customers,
          we will forward your request to the relevant customer. We will inform you that
          we have forwarded your request and provide you with the contact details
          of the relevant client.
        </p>
      </div>

      <div className="mb-4">
        <h2>12. Our details</h2>

        <p>1. Our website and applications are owned and operated by VentorTech OÜ</p>
        <p>
          2. Our company is registered with the authorities in Estonia with the registration number
          14546949, Tax ID: EE102093232.
        </p>
        <p>
          3. Our headquarters is located at Tööstuse tn 48a, Tallinn, 10416, Estonia which
          also serves as our primary place of business.
        </p>
        <p>4. You can contact us in the following ways:</p>
        <ul>
          <li>
            1. By using our support portal:
            <a href="https://support.ventor.tech/" target="_blank" rel="noreferrer">https://support.ventor.tech/</a>
            .
          </li>
          <li>2. By email on support@ventor.tech or hello@ventor.tech.</li>
        </ul>
      </div>
    </div>
  </div>
);

export default PrivacyPolicy;
