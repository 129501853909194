import React from 'react';
import PropTypes from 'prop-types';

import Plan from './View';

const YearlyPlans = ({ plans, onSubscribe }) => (
  <>

    <div className="row">
      {
          plans.map((plan, i) => (
            <Plan plan={plan} key={plan.id} isPopular={i === 1} onSubscribe={onSubscribe} />
          ))
        }
    </div>

    <div className="row">
      <div className="callout callout-info">
        Dive into a year of savings with our yearly plans. Payment is processed at the start
        of your subscription, granting you access to a substantial volume of prints.
        Should your needs grow, we&apos;re flexible. Any prints beyond your plan&apos;s
        initial volume will incur an additional charge, billed at the end of your
        subscription period. If you choose to cancel, your subscription will remain in
        effect until the current period concludes, and any charges due will be processed
        at that time.
      </div>
    </div>

  </>

);

YearlyPlans.propTypes = {
  plans: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    period: PropTypes.string.isRequired,
    prints_included: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
    price_per_print: PropTypes.number.isRequired,
  })).isRequired,
  onSubscribe: PropTypes.func,
};

YearlyPlans.defaultProps = {
  onSubscribe: null,
};

export default YearlyPlans;
