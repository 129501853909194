import React from 'react';
import PropTypes from 'prop-types';

import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { TrialPlan } from './View';
import { sendRequest } from '../../utils';

const TrialPlans = ({ plans }) => {
  const history = useHistory();

  const [error, setError] = React.useState(null);

  const onStartFreeTrial = (planId) => {
    const opts = {
      plan_id: planId,
      payment_method_id: null,
      country_code: null,
      full_name: null,
      email: null,
      vat_number: null,
    };

    sendRequest('subscriptions', 'POST', opts)
      .then((result) => {
        if (result.error) {
          throw result.message;
        }
        return result;
      })
      .then(() => {
        // Redirect to Getting Started page
        history.push('/getting-started-1');
      })
      .catch((err) => {
        setError(err.error || err);
      });
  };

  return (
    <>
      {
        error && (
        <Alert variant="danger">
          <Alert.Heading>Oh snap!</Alert.Heading>
          <div>{error}</div>
        </Alert>
        )
      }

      <div className="row">
        {
          plans.map((plan) => (
            <TrialPlan plan={plan} key={plan.id} onStartFreeTrial={onStartFreeTrial} />
          ))
        }
      </div>

      <div className="row">
        <div className="callout callout-info">
          We&apos;re so confident you&apos;ll love our service that we&apos;re offering a
          free trial for all new users. Enjoy 50 free prints, no strings
          attached. If you&apos;re not satisfied, you can cancel at any time.
        </div>
      </div>
    </>
  );
};

TrialPlans.propTypes = {
  plans: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    period: PropTypes.string.isRequired,
    prints_included: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
    price_per_print: PropTypes.number.isRequired,
  })).isRequired,
};

export default TrialPlans;
