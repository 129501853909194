import React from 'react';
import ScrollToTop from '../Components/ScrollToTop';

const FAQ = () => (
  <div className="container">
    <div className="row">
      <div className="col-xl-10 offset-xl-1">
        <div className="row mb-4">
          <div className="col-md-12">
            <h2 className="mt-4 mb-4">FAQ</h2>
            <ul className="simple">
              <li>
                <a className="reference internal text-dark" href="#does-every-computer-in-the-company-that-needs-to-print-need-to-install-the-direct-print-client-app-on-the-local-computer">
                  Does every computer in the company that needs to print, need to install the
                  Direct Print Client app on the local computer? Or only the computers where
                  the printer is physically attached?
                </a>
              </li>
              <br />
              <li>
                <a className="text-dark" href="#are-there-any-limitations-on-the-side-of-hosting-odoo-odoo-sh-on-premise-server">
                  Are there any limitations on the side of hosting Odoo (odoo.sh,
                  on-premise server)? We use Docker/Kubernetes based deployments.
                  Are you aware of any issues with such environments?
                </a>
              </li>
              <br />
              <li>
                <a className="text-dark" href="#are-you-using-cups-as-a-printer-server">
                  Are you using CUPS as a printer server? How does this work on odoo.sh.
                  Do we have to make a vpn connection between odoo.sh and the warehouse?
                </a>
              </li>
              <br />
              <li>
                <a className="text-dark" href="#is-this-similar-to-odoo-s-iot-app">
                  Is this similar to Odoo&apos;s IOT app?  Can you explain the differences?
                </a>
              </li>
              <br />
              <li>
                <a className="text-dark" href="#i-have-been-able-to-print-directly-from-odoo-with-my-printer-but-the-printed-document-is-misaligned">
                  I have been able to print directly from Odoo with my printer, but
                  the printed document is misaligned / printed incorrectly / went to the 2nd page /
                  printing as I’m not expecting it...
                </a>
              </li>
              <br />
              <li>
                <a className="text-dark" href="#are-there-any-additional-costs-associated-with-purchase-of-the-app-on-top-of-the-application-cost">
                  Are there any additional costs associated with purchase of the app on top
                  of the application cost?
                </a>
              </li>
              <br />
              <li>
                <a className="text-dark" href="#i-paid-for-the-app-already-why-should-i-pay-additionally-for-the-subscription">
                  I paid for the app already. Why should I pay additionally for the subscription?
                </a>
              </li>
              <br />
              <li>
                <a className="text-dark" href="#i-have-a-problem-technical-or-functional-question-about-your-module-do-you-give-support">
                  I have a problem / technical or functional question about your module.
                  Do you give support?
                </a>
              </li>
              <br />
              <li>
                <a className="text-dark" href="#what-happens-when-i-move-to-another-odoo-version">
                  What happens when I move to another Odoo version? Do I need to buy the new version
                  of your app, or do I get the new version?
                </a>
              </li>
              <br />
              <li>
                <a className="text-dark" href="#i-want-to-be-notified-when-a-new-module-release-is-ready-so-as-to-have-the-latest-features-why-am-i-not-notified">
                  I want to be notified when a new module release is ready, so as to have
                  the latest features. Why am I not notified?
                </a>
              </li>
              <br />
              <li>
                <a className="text-dark" href="#how-to-check-what-version-of-the-odoo-direct-print-module-i-have">
                  How to check what version of the Odoo Direct Print module I have?
                </a>
              </li>
              <br />
              <li>
                <a className="text-dark" href="#i-have-purchased-a-module-where-can-i-download-the-latest-odoo-direct-print-module-version">
                  I have purchased a module. Where can I download the latest Odoo Direct Print
                  module version?
                </a>
              </li>
              <br />
              <li>
                <a className="text-dark" href="#we-have-a-custom-developed-module-where-we-want-to-enable-automatic-printing-also">
                  We have a custom developed module where we want to enable automatic printing also.
                  Can you support our development team so they will be able to implement this?
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* 1 */}
        <div className="row mb-4 pt-4" id="does-every-computer-in-the-company-that-needs-to-print-need-to-install-the-direct-print-client-app-on-the-local-computer">
          <div className="col-md-12">
            <p>
              <h5>
                Does every computer in the company that needs to print, need to install the
                Direct Print Client app on the local computer? Or only the computers where
                the printer is physically attached?
              </h5>
            </p>
            <p>
              It&apos;s enough to have only one machine that has access to
              all needed printers. We even recommend to set-up a separate PC for this.
              E.g. We have configured a Raspberry PI 4 in our office for printing purposes.
              It absolutely doesn&apos;t matter where the printers are: connected to a local or
              external network. If the Direct Print Client client sees them, you can print.
            </p>
          </div>
        </div>

        {/* 2 */}
        <div className="row mb-4 pt-4" id="are-there-any-limitations-on-the-side-of-hosting-odoo-odoo-sh-on-premise-server">
          <div className="col-md-12">
            <p>
              <h5>
                Are there any limitations on the side of hosting Odoo (odoo.sh, on-premise server)?
                We use Docker/Kubernetes based deployments. Are you aware of any issues
                with such environments?
              </h5>
            </p>
            <p>
              No issues if your Odoo server has internet access.
            </p>
          </div>
        </div>

        {/* 3 */}
        <div className="row mb-4 pt-4" id="are-you-using-cups-as-a-printer-server">
          <div className="col-md-12">
            <p>
              <h5>
                Are you using CUPS as a printer server? How does this work on odoo.sh.
                Do we have to make a vpn connection between odoo.sh and the warehouse?
              </h5>
            </p>
            <p>
              No need to make a VPN connection. You will just need to install a special
              Direct Print Client on any local machine in your network with printers.
              CUPS will be needed only if this machine will be Linux based.
            </p>
          </div>
        </div>

        {/* 4 */}
        <div className="row mb-4 pt-4" id="is-this-similar-to-odoo-s-iot-app">
          <div className="col-md-12">
            <p>
              <h5>
                Is this similar to Odoo&apos;s IOT app?  Can you explain the differences?
              </h5>
            </p>
            <p>
              The main differences are:
            </p>
            <ul>
              <li>
                Odoo IoT requires additional hardware that you order from Odoo for subscription
                (IoT Box). You can use the Odoo Direct Print app with any machine that has access
                to all needed printers
              </li>
              <li>Odoo IoT works only with Odoo Enterprise</li>
              <li>
                Odoo direct print can print documents automatically and is configurable in
                a flexible way (Delivery slips, shipping labels, other reports...)
              </li>
              <li>
                Odoo Direct Print works with any remote or local (USB, Wi-Fi, Bluetooth)
                printer and connected to any OS (Windows/Mac/Linux)
              </li>
            </ul>
          </div>
        </div>

        {/* 5 */}
        <div className="row mb-4 pt-4" id="i-have-been-able-to-print-directly-from-odoo-with-my-printer-but-the-printed-document-is-misaligned">
          <div className="col-md-12">
            <p>
              <h5>
                I have been able to print directly from Odoo with my printer, but
                the printed document is misaligned / printed incorrectly / went to the 2nd page /
                printing as I’m not expecting it...
              </h5>
            </p>
            <p>
              Issue like this usually means 2 things:
            </p>
            <ul>
              <li>Either not calibrated printer. So you need to calibrate it</li>
              <li>
                Incorrect implementation of ZPL. Usually, this is not a question to Odoo
                or anything else.
              </li>
            </ul>
            <p>
              To find out what is the issue - instead of printing ZPL/PDF from Odoo, download it as
              a file (via Download dropdown or from attachments). And then send it directly
              to your Zebra printer from your computer.
              <br />
              <br />
              Usually, you will find that it is already printed incorrectly by directly sending it
              to the printer. That means you need to fix your ZPL. Remember that ZPL is
              a programming language and incorrectly inserted symbols may break even
              an already calibrated printer. And you will have to calibrate it again.
            </p>
          </div>
        </div>

        {/* 6 */}
        <div className="row mb-4 pt-4" id="are-there-any-additional-costs-associated-with-purchase-of-the-app-on-top-of-the-application-cost">
          <div className="col-md-12">
            <p>
              <h5>
                Are there any additional costs associated with purchase of the app on top
                of the application cost?
              </h5>
            </p>
            <p>
              Our software is based on&nbsp;
              <a className="text-warning" href="https://printnode.com" target="_blank" rel="noreferrer">printnode.com</a>
              &nbsp;This is a paid service.
              It has a monthly subscription.
              What we have done - we simplified API Key acquiring for you so it will be
              plug&play software (previously too many setup steps).
              Prices are the same both on&nbsp;
              <a className="text-warning" href="https://print.ventor.tech" target="_blank" rel="noreferrer">print.ventor.tech</a>
              &nbsp;
              and&nbsp;
              <a className="text-warning" href="https://printnode.com" target="_blank" rel="noreferrer">printnode.com</a>
              ).
              <br />
              <br />
              We are the only&nbsp;
              <a className="text-warning" href="https://www.printnode.com/en/docs/plugins-and-libraries" target="_blank" rel="noreferrer">officially recognized</a>
              &nbsp;software for Odoo for printnode.com
            </p>
          </div>
        </div>

        {/* 7 */}
        <div className="row mb-4 pt-4" id="i-paid-for-the-app-already-why-should-i-pay-additionally-for-the-subscription">
          <div className="col-md-12">
            <p>
              <h5>
                I paid for the app already. Why should I pay additionally for the subscription?
              </h5>
            </p>
            <p>
              Why actually printing is a paid service? Unfortunately technically it is not possible
              to print from web application/Odoo.sh on your printer without downloading PDF.
              This is a technical limitation of printing protocol (so problem not with Odoo
              but with ANY web software). That is why there are many services like printnode and
              all of them are paid and all more or less of the similar price. So if you are
              looking for something that will be printed from Odoo directly for free -
              there is only one solution for this. Hire team of developers.
              Get VPN service setup between your office where all your printers are located
              and develop your own functionality based on Community work.
              <br />
              <br />
              About payment for the module itself. As you can see prices on&nbsp;
              <a className="text-warning" href="https://print.ventor.tech" target="_blank" rel="noreferrer">print.ventor.tech</a>
              &nbsp;are the same as on printnode.com. We pay printnode.com ourselves for
              a Developer Account a monthly fee. So most of your subscription fee just goes
              directly to Printnode and we are different companies of course.
              <br />
              <br />
              Payment for the module allows us to develop it constantly. If you look at
              our Changelog (on the “Documentation” tab of the module on the App Store) -
              we release and improve every month by adding new features. Without that payment
              it will be very hard to have a support team and there will be no team of developers
              constantly adding new features and releasing modules for new Odoo versions.
            </p>
          </div>
        </div>

        {/* 8 */}
        <div className="row mb-4 pt-4" id="i-have-a-problem-technical-or-functional-question-about-your-module-do-you-give-support">
          <div className="col-md-12">
            <p>
              <h5>
                I have a problem / technical or functional question about your module.
                Do you give support?
              </h5>
            </p>
            <p>
              Sure! Just drop us an email to the support@ventor.tech and we will
              respond back to you! =)
              <br />
              <br />
              NOTE: if you have a question regarding Printnode Desktop Client
              (for Windows/Linux/Mac) itself we will be here to help you with them in case
              you are subscribed through us.
              <br />
              <br />
              If you have a direct subscription to printnode.com - you need to contact
              printnode.com support directly.
            </p>
          </div>
        </div>

        {/* 9 */}
        <div className="row mb-4 pt-4" id="what-happens-when-i-move-to-another-odoo-version">
          <div className="col-md-12">
            <p>
              <h5>
                What happens when I move to another Odoo version? Do I need to buy the new version
                of your app, or do I get the new version?
              </h5>
            </p>
            <p>
              You need to buy a separate module for any new Odoo version.
              This is the Official Odoo Apps Policy. Learn more
              {' '}
              <a className="text-warning" href="https://apps.odoo.com/apps/sales-conditions" target="_blank" rel="noreferrer">here</a>
              .
              <br />
              <br />
              Your monthly subscription to print services will be saved during migration
              to a new Odoo version.
            </p>
          </div>
        </div>

        {/* 10 */}
        <div className="row mb-4 pt-4" id="i-want-to-be-notified-when-a-new-module-release-is-ready-so-as-to-have-the-latest-features-why-am-i-not-notified">
          <div className="col-md-12">
            <p>
              <h5>
                I want to be notified when a new module release is ready, so as to have
                the latest features. Why am I not notified?
              </h5>
            </p>
            <p>
              We already contacted Odoo and they officially said that they have no possibility
              to inform you automatically about new module versions released. So you will never get
              notification about it. Odoo App Store is too “young” and lacks many features
              that are standard in all modern App Stores. So unfortunately you always need
              to check manually
              <br />
              <br />
              Good news that we are planning to add automatic notifications about
              new module releases into our module. So you will be able to get notified about it.
              But again - this is purely our initiative and no other app store modules have
              this feature. We would prefer that Odoo themselves will do it - but for now
              that is not their priority.
              <br />
              <br />
              Feel free to subscribe to our&nbsp;
              <a className="text-warning" href="https://ventor.tech/blog/#subscribe" target="_blank" rel="noreferrer">newsletter</a>
              &nbsp;and&nbsp;
              <a className="text-warning" href="https://www.youtube.com/c/VentorTech" target="_blank" rel="noreferrer">YouTube</a>
              &nbsp;to be on the crest of the wave.
            </p>
          </div>
        </div>

        {/* 11 */}
        <div className="row mb-4 pt-4" id="how-to-check-what-version-of-the-odoo-direct-print-module-i-have">
          <div className="col-md-12">
            <p>
              <h5>
                How to check what version of the Odoo Direct Print module I have?
              </h5>
            </p>
            <p>
              <ol>
                <li>
                  First you need to activate Developers mode. How to do this is described&nbsp;
                  <a className="text-warning" href="https://www.odoo.com/documentation/15.0/applications/general/developer_mode.html" target="_blank" rel="noreferrer">here</a>
                </li>
                <li>
                  Then you can go to your Odoo -&gt; Apps -&gt; find the Odoo Direct Print module
                  and click on it. And you will see their module version as on the screenshot below
                </li>
              </ol>

              <br />
              <br />
              <img className="w-75 d-block mx-auto" src="/images/faq/faq-1.png" alt="Module Info" />
            </p>
          </div>
        </div>

        {/* 12 */}
        <div className="row mb-4 pt-4" id="i-have-purchased-a-module-where-can-i-download-the-latest-odoo-direct-print-module-version">
          <div className="col-md-12">
            <p>
              <h5>
                I have purchased a module. Where can I download the latest Odoo Direct Print
                module version?
              </h5>
            </p>
            <p>
              You have purchased a module on Odoo App Store. If you go to the Odoo App Store to find
              the proper Odoo version that you purchased (also you should be logged in as an account
              that has made the purchase), then you can always download the up-to-date
              version of modules.
              <br />
              <br />
              <img className="w-75 d-block mx-auto" src="/images/faq/faq-2.jpg" alt="Download button" />
            </p>
          </div>
        </div>

        {/* 13 */}
        <div className="row mb-4 pt-4" id="we-have-a-custom-developed-module-where-we-want-to-enable-automatic-printing-also">
          <div className="col-md-12">
            <p>
              <h5>
                We have a custom developed module where we want to enable automatic printing also.
                Can you support our development team so they will be able to implement this?
              </h5>
            </p>
            <div className="mb-3">
              <iframe
                className="w-75 d-block mx-auto"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/n_1Owu_ZgCI"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
            <p>
              If we do not have yet such a print scenario that you need, then for your developers
              it is rather simple to do by following steps below. We made our module to be very easy
              to adapt to your customizations.
              <br />
              <br />
              In place inside your module where you want to print a document do the following:
              <br />
              <br />
              (1) First insert below code to get current users printer and printer tray bin.
              It is needed to proceed with the printing. If the user has no printer defined -
              then there is not possible to print:
            </p>
            <pre>
              <code>
                {`user = self.env.user
printer_id, printer_bin = user.get_report_printer(report.id)`}
              </code>
            </pre>
            <ul className="font-italic">
              <li>
                report  - object of type ir.actions.report. Corresponds to the printed report
                you are going to print
              </li>
            </ul>
            <p>
              (2) After this just call printing like below. And you are all set!
            </p>
            <pre>
              <code>
                {`options = {'bin': printer_bin} if printer_bin else {}
printer_id.printnode_print(
    report,
    objects,
    copies=1,
    options=options,
)`}
              </code>
            </pre>
            <ul className="font-italic">
              <li>
                objects - is a list of any objects (browse records) for which you want to print
                a specified report. For example, a list of invoices / sales orders etc
              </li>
              <li>
                report  - object of type ir.actions.report. Corresponds to the printed report
                you are going to print
              </li>
              <li>copies - this parameter allows you to specify number of copies to print</li>
            </ul>
          </div>
        </div>

        <ScrollToTop />
      </div>
    </div>
  </div>
);

export default FAQ;
