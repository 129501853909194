import React from 'react';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { Alert, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import Loader from '../Components/Loader';

import {
  login, reducer, sendRequest, useAuth,
} from '../utils';

function Login() {
  const [logged] = useAuth();

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: {}, message: '', error: '', isLoading: false, isError: false,
    },
  );

  if (logged) {
    return <Redirect to="/profile" />;
  }

  const onSubmitClick = (e) => {
    e.preventDefault();

    dispatch({ type: 'FETCH_INIT' });

    const opts = {
      email,
      password,
    };

    sendRequest('auth/login', 'POST', opts)
      .then((response) => {
        if (response.status_code === 200) {
          login(response.data);
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      })
      .catch(() => {
        dispatch({
          type: 'FETCH_FAILURE',
          error: 'Our service is not available right now. Please contact us: support@ventor.tech',
        });
      });
  };

  const handleSuccessGoogleLogin = (googleData) => {
    dispatch({ type: 'FETCH_INIT' });

    const opts = {
      google_token: googleData.credential,
    };

    sendRequest('auth/google', 'POST', opts)
      .then((response) => {
        if (response.status_code === 200) {
          login(response.data);
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      })
      .catch(() => {
        dispatch({
          type: 'FETCH_FAILURE',
          error: 'Our service is not available right now. Please contact us: support@ventor.tech',
        });
      });
  };

  const handleFailureGoogleLogin = (error) => {
    dispatch({
      type: 'FETCH_FAILURE',
      error: `Google login failed. ${error}`,
    });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <div className="row justify-content-md-center">
        <div className="col-md-6 mr-md-3 mr-sm-3 mr-lg-3 mr-xl-3">
          <h2 className="mb-4">Login</h2>

          {
            state.isLoading
              ? <Loader />
              : (
                <>
                  <p>
                    Do not have an account?&nbsp;
                    <Link className="text-warning" to="/register">Register</Link>
                  </p>

                  <div className="mt-4 google-auth-button">
                    <GoogleLogin
                      onSuccess={handleSuccessGoogleLogin}
                      onError={handleFailureGoogleLogin}
                      text="continue_with"
                      size="large"
                    />
                  </div>

                  <div className="login-separator" />

                  {state.message && <Alert variant="success">{state.message}</Alert>}
                  {state.error && <Alert variant="danger">{state.error}</Alert>}

                  <form action="#">
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email address
                        <input
                          id="email"
                          type="email"
                          className="form-control"
                          onChange={handleEmailChange}
                          value={email}
                        />
                      </label>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="password" className="form-label">
                        Password
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          onChange={handlePasswordChange}
                          value={password}
                        />
                      </label>
                    </div>
                    <button type="submit" className="btn btn-warning mr-4 mb-3" onClick={onSubmitClick}>Login</button>
                    <LinkContainer to="/forgot">
                      <Button variant="outline-warning" className="mr-4 mb-3">Forgot Password</Button>
                    </LinkContainer>
                    <LinkContainer to="/resend">
                      <Button variant="outline-warning" className="float-xl-right mb-3">Resend Confirmation</Button>
                    </LinkContainer>
                  </form>
                </>
              )
          }
        </div>
      </div>
    </GoogleOAuthProvider>
  );
}

export default Login;
