// import logo from './logo.svg';
import './App.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import {
  Route, Switch, useHistory,
} from 'react-router-dom';

import { fas } from '@fortawesome/free-solid-svg-icons';
import Login from './Auth/Login';
import Devices from './Printnode/Devices';
import History from './Printnode/History';
import Menu from './Components/Menu';
import Plans from './Payments/Plans/List';
import Register from './Auth/Register';
import ResendConfirmation from './Auth/ResendConfirmation';
import Confirm from './Auth/Confirm';
import ForgotPassword from './Auth/ForgotPassword';
import Restore from './Auth/Restore';
import PrivateRoute from './Auth/PrivateRoute';
import ProfileEdit from './Account/ProfileEdit';
import { ls } from './utils';
import GetAPIKey from './Account/GetAPIKey';
import AddPaymentMethod from './Account/AddPaymentMethod';
import Home from './Pages/Home';
import Docs from './Pages/Docs';
import FAQ from './Pages/FAQ';
import Profile from './Account/Profile';
import Checkout from './Payments/Checkout';
import Footer from './Components/Footer';
import AutoScrollToTop from './Components/AutoScrollToTop';
import HowToLeaveReview from './Pages/HowToReview';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import LoginAsCustomer from './Auth/LoginAsCustomer';
import GettingStartedStep1 from './Payments/GettingStartedStep1';
import GettingStartedStep2 from './Payments/GettingStartedStep2';
import Service from './Pages/Service';
import PayForExtraPrints from './Account/PayForExtraPrints';

library.add(fas);

function App() {
  // Store redirect URL to handle redirect after checkout
  // TODO: Should be better solution!
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const redirectURL = params.get('redirect_url');

  if (redirectURL) {
    ls.set('REDIRECT_URL', redirectURL);
  }

  return (
    <>
      <Menu />
      <div className="container mt-4 mb-4 flex-fill">
        <Route component={AutoScrollToTop} />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/plans" component={Plans} />
          <Route exact path="/service" component={Service} />
          <Route path="/confirm" component={Confirm} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot" component={ForgotPassword} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/resend" component={ResendConfirmation} />
          <Route exact path="/restore" component={Restore} />
          <Route exact path="/docs" component={Docs} />
          <Route exact path="/faq" component={FAQ} />
          <Route exact path="/how-to-leave-review" component={HowToLeaveReview} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />

          <PrivateRoute exact path="/profile" component={Profile} />
          <PrivateRoute exact path="/profile/edit" component={ProfileEdit} />
          <PrivateRoute exact path="/profile/payment-methods/add" component={AddPaymentMethod} />
          <PrivateRoute exact path="/devices" component={Devices} />
          <PrivateRoute exact path="/history" component={History} />
          <PrivateRoute exact path="/checkout/:planId" component={Checkout} />
          <PrivateRoute exact path="/getting-started-1" component={GettingStartedStep1} />
          <PrivateRoute exact path="/getting-started-2" component={GettingStartedStep2} />
          <Route exact path="/get-api-key" component={GetAPIKey} />
          <Route exact path="/login-as-customer/:accessToken" component={LoginAsCustomer} />
          <PrivateRoute exact path="/pay-for-extra-prints" component={PayForExtraPrints} />
        </Switch>
      </div>
      <Footer />
    </>
  );
}

export default App;
