import React from 'react';
import { useHistory, useParams } from 'react-router';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { Alert } from 'react-bootstrap';
import CheckoutForm from './CheckoutForm';
import { reducer, sendRequest } from '../utils';
import Loader from '../Components/Loader';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Checkout = () => {
  const { planId } = useParams();
  const history = useHistory();

  const [state, dispatch] = React.useReducer(
    reducer,
    { data: [], isLoading: true, isError: false },
  );

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    const subscriptionPromise = sendRequest('subscriptions', 'GET', { active_only: true, not_trial: true });
    const plansPromise = sendRequest('plans', 'GET');

    Promise.all([subscriptionPromise, plansPromise])
      .then(([subscription, plans]) => {
        // If user has active subscription, redirect to Profile page
        if (subscription.data.length > 0) {
          history.push('/profile');
        }

        const plan = plans.data.filter((p) => p.id === Number(planId))[0];

        if (plan) {
          dispatch({
            type: 'FETCH_SUCCESS',
            payload: plan,
          });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: 'Wrong plan ID' });
        }
      })
      .catch(() => dispatch({ type: 'FETCH_FAILURE' }));
  }, []);

  const onSuccessSubscription = () => {
    history.push('/getting-started-1');
  };

  const onCancel = () => {
    history.goBack();
  };

  if (state.isLoading) {
    return <Loader />;
  }

  return (
    <>
      <h1 className="mt-4 mb-4">Checkout</h1>

      {
        state.isError
          ? <Alert variant="danger">{state.error}</Alert>
          : (
            <Elements stripe={stripePromise}>
              <CheckoutForm
                plan={state.data}
                handleSuccessSubscription={onSuccessSubscription}
                handleCancel={onCancel}
              />
            </Elements>
          )
        }
    </>
  );
};

export default Checkout;
