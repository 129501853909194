import React from 'react';
import ScrollToTop from '../Components/ScrollToTop';

const Docs = () => (
  <div className="container">
    <div className="row">
      <div className="col-xl-10 offset-xl-1">
        <div className="row mb-4">
          <h1 className="d-block mt-4 mb-4 text-warning">Contents</h1>
          <div className="w-100">
            <ul className="simple">
              <li><a className="reference internal text-warning" href="#installation">Installation</a></li>
              <li><a className="reference internal text-warning" href="#configuration">Configuration</a></li>
              <li>
                <a className="reference internal text-warning" href="#automatic-printing">Automatic Printing</a>
                <ul className="simple">
                  <li><a className="reference internal text-warning" href="#print-scenarios">Print Scenarios</a></li>
                  <li><a className="reference internal text-warning" href="#print-action-buttons">Print Action Buttons</a></li>
                </ul>
              </li>
              <li><a className="reference internal text-warning" href="#troubleshooting">Troubleshooting</a></li>
            </ul>
          </div>
        </div>

        <div className="row mb-4" id="installation">
          <h1>Installation</h1>

          <div className="section w-100" id="installation-1">
            <div className="container video">
              <iframe
                src="https://www.youtube.com/embed/NenQLJlxcA4"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>

          <div className="section w-100" id="installation-1">
            <p className="mt-3 mb-3">1. Download the app from Odoo Store and upload to your Odoo server</p>
            <img className="d-block mx-auto w-85 mt-3 mb-3" alt="Download the app from Odoo Store" src="/images/docs/docs-1.jpg" />
            <p className="mt-3 mb-3">
              <a className="text-warning" href="https://youtu.be/p4KE10FbYk0" target="_blank" rel="noreferrer">How to install on Odoo.sh</a>
            </p>
            <p className="mt-3 mb-3">
              This step is usually done by technical support staff.
            </p>
          </div>

          <div className="section w-100" id="installation-2">
            <p className="mt-3 mb-3">2. Install the app</p>
            <img className="d-block mx-auto w-85 mt-3 mb-3" alt="Install the app" src="/images/docs/docs-2.jpg" />
          </div>

          <div className="section w-100" id="installation-3">
            <p className="mt-3 mb-3">3. Then, you need to subscribe to printing services. Click &quot;Continue&quot;</p>
            <img className="d-block mx-auto w-85 mt-3 mb-3" alt="Subscribe to printing services" src="/images/docs/docs-3.jpg" />

            <p className="mt-3 mb-3">
              Note, if you already have a PrintNode account, you can enter your existing API key.
            </p>
            <p className="mt-3 mb-3">
              If you missed this pop-up, you can always retrieve it in the Settings screen.
            </p>
            <img className="d-block mx-auto w-85 mt-3 mb-3" alt="Settings screen" src="/images/docs/docs-4.jpg" />
          </div>

          <div className="section w-100" id="installation-4">
            <p className="mt-3 mb-3">4. Create an account on print.ventor.tech and confirm your email address</p>
          </div>

          <div className="section w-100" id="installation-5">
            <p className="mt-3 mb-3">
              5. Choose a&nbsp;
              <a className="text-warning" href="/plans" target="_blank">plan</a>
              &nbsp;and subscribe
            </p>
            <img className="d-block mx-auto w-85 mt-3 mb-3" alt="Choose a plan" src="/images/docs/docs-5.jpg" />
            <p className="mt-3 mb-3">
              Then, go through the checkout process. Don’t forget to apply your VAT number
              if you are an EU resident.
            </p>
          </div>

          <div className="section w-100" id="installation-6">
            <p className="mt-3 mb-3">6. Download and install Desktop client</p>
            <p className="mt-3 mb-3">
              You can always find it in your&nbsp;
              <a className="text-warning" href="/profile" target="_blank">account</a>
              &nbsp;on print.ventor.tech
            </p>
          </div>

          <div className="section w-100" id="installation-7">
            <p className="mt-3 mb-3">7. Login to the desktop client</p>
            <img className="d-block mx-auto w-85 mt-3 mb-3" alt="Login to the desktop client" src="/images/docs/docs-6.jpg" />
            <p className="mt-3 mb-3">
              Copy credentials from the installation wizard or find them in your&nbsp;
              <a className="text-warning" href="/profile" target="_blank">account</a>
            </p>
            <img className="d-block mx-auto w-85 mt-3 mb-3" alt="Login to the desktop client" src="/images/docs/docs-7.jpg" />
          </div>

          <div className="section w-100" id="installation-8">
            <p className="mt-3 mb-3">8. Then, return to the installation wizard and click &quot;Go back to Odoo&quot;. Your API key will be filled in and printers imported automatically</p>
            <p className="mt-3 mb-3">
              If your session was interrupted, you need to go to your
              Odoo &gt; Direct Print &gt; Configuration &gt; Settings and paste in your API key
              from your&nbsp;
              <a className="text-warning" href="/profile" target="_blank">account</a>
              &nbsp;on print.ventor.tech. Then click on “Import Printers & Scales”.
            </p>
            <img className="d-block mx-auto w-85 mt-3 mb-3" alt="Login to the desktop client" src="/images/docs/docs-8.jpg" />
            <p className="mt-3 mb-3">Now you need to configure your printing environment.</p>
          </div>
        </div>

        <div className="row mb-4" id="configuration">
          <h1>Configuration</h1>
          <div className="section w-100" id="configuration-9">
            <p className="mt-3 mb-3">9. Go to Odoo Direct Print settings and set up default printers and scales (Don&apos;t forget to set up a shipping label if needed)</p>
            <img className="d-block mx-auto w-85 mt-3 mb-3" alt="Odoo Direct Print settings" src="/images/docs/docs-9.jpg" />
          </div>

          <div className="section w-100" id="configuration-10">
            <p className="mt-3 mb-3">10. Go to user preferences, set up the default printers and scales, and click in the &quot;Auto-print via Direct Print&quot; checkbox</p>
            <img className="d-block mx-auto w-85 mt-3 mb-3" alt="Odoo User Preferences" src="/images/docs/docs-10.jpg" />
            <p className="mt-3 mb-3">
              If this checkbox is set, then all documents will be auto-forwarded to the printer
              instead of downloading in PDF.
            </p>
          </div>

          <div className="section w-100" id="configuration-11">
            <p className="mt-3 mb-3">11. That&apos;s it; you can now print directly on your default printers. Try to print any document, and make sure that your printer is switched on!</p>
            <img className="d-block mx-auto w-85 mt-3 mb-3" alt="Print button" src="/images/docs/docs-11.jpg" />
            <p className="mt-3 mb-3">
              You will still be able to download any report instead of printing by clicking
              the &quot;Download&quot; button.
            </p>
          </div>
        </div>

        <div className="row mb-4" id="automatic-printing">
          <h1>Automatic printing and print scenarios</h1>
          <div className="section w-100" id="print-scenarios">
            <h3 className="mt-3 mb-3">Print Scenarios</h3>
            <p className="mt-3 mb-3">
              12. The app contains many preconfigured print scenarios.
              You can simply activate and automate your printing workflows even more efficiently.
            </p>
            <img className="d-block mx-auto w-85 mt-3 mb-3" alt="Print Scenarios" src="/images/docs/docs-12.jpg" />
          </div>

          <div className="section w-100" id="print-action-buttons">
            <h3 className="mt-3 mb-3">Print Action Buttons</h3>
            <p className="mt-3 mb-3">
              13. If you are an advanced user, you can configure your custom auto-print actions
              on your own via the &quot;Print Action Buttons&quot; menu.
            </p>
            <img className="d-block mx-auto w-85 mt-3 mb-3" alt="Print Action Buttons Configuration" src="/images/docs/docs-13.jpg" />

            <p className="mt-3 mb-3">
              If you need to set up an additional condition for print action buttons
              (e.g. print delivery slip only in the Delivery zone, or for deliveries shipped
              to particular countries) you should define Domain
            </p>

            <img className="d-block mx-auto w-85 mt-3 mb-3" alt="Print Action Buttons Domain" src="/images/docs/docs-14.png" />

            <div className="section" id="how-to-set-up-domains">
              <p className="mt-3 mb-3">How to set up domains:</p>
              <img className="d-block mx-auto w-85 mt-3 mb-3" alt="Domains setup" src="/images/docs/docs-15.png" />
              <p className="mt-3 mb-3">
                Leave the field &quot;Printer&quot; blank for print action button in case you need
                to print reports on user&apos;s printer (set up in user preferences)
              </p>
              <img className="d-block mx-auto w-85 mt-3 mb-3" alt="Printer Configuration" src="/images/docs/docs-16.png" />

            </div>
          </div>
        </div>

        <div className="row mb-4" id="troubleshooting">
          <h1>Troubleshooting</h1>
          <div className="section w-100">
            <p className="mt-3 mb-3">
              If the system downloads reports instead of printing them,
              please check that the&nbsp;
              <strong>&quot;Print via Direct Print Client&quot;</strong>
              &nbsp;checkbox has been ticked:
            </p>

            <img className="d-block mx-auto w-85 mt-3 mb-3" alt="Print via Direct Print Client checkbox" src="/images/docs/docs-17.jpg" />

          </div>
        </div>
      </div>
    </div>
    <ScrollToTop />
  </div>

);

export default Docs;
