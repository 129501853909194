import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { Redirect } from 'react-router-dom';
import { reducer, sendRequest, useAuth } from '../utils';
import Loader from '../Components/Loader';

const { REACT_APP_RECAPTCHA_SITE_KEY } = process.env;

function ResendConfirmation() {
  const [logged] = useAuth();
  const recaptchaRef = React.createRef();

  const [email, setEmail] = React.useState('');

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: {}, message: '', error: '', isLoading: false, isError: false,
    },
  );

  if (logged) {
    return <Redirect to="/" />;
  }

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch({ type: 'FETCH_INIT' });

    // Get the recaptcha token
    const token = recaptchaRef.current.getValue();
    if (!token) {
      dispatch({ type: 'FETCH_FAILURE', error: 'Please verify you are not a robot.' });
      return;
    }

    const opts = {
      email,
      recaptcha_token: token,
    };

    sendRequest('auth/resend', 'POST', opts)
      .then((response) => {
        if (response.status_code === 200) {
          dispatch({ type: 'FETCH_SUCCESS', message: response.message });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      });
  };

  return (
    <div className="row justify-content-md-center">
      <div className="col-md-6">
        <h2 className="mb-4">Resend Confirmation Email</h2>
        {state.isLoading
          ? <Loader />
          : (
            <>
              {
                state.error
                  ? <div className="alert alert-danger" role="alert">{state.error}</div>
                  : ''
              }

              {
                state.message
                  ? <div className="alert alert-success" role="alert">{state.message}</div>
                  : ''
              }

              <form onSubmit={onSubmit}>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email address
                    <input
                      id="email"
                      type="email"
                      className="form-control"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </label>
                </div>

                <div className="mb-3">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={REACT_APP_RECAPTCHA_SITE_KEY}
                  />
                </div>

                <button type="submit" className="btn btn-warning">Resend</button>
              </form>
            </>
          )}
      </div>
    </div>
  );
}

export default ResendConfirmation;
