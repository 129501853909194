import React from 'react';
import PropTypes from 'prop-types';

import Plan from './View';

const YearlyPlans = ({ plans, onSubscribe }) => (
  <>
    <div className="row">
      {
          plans.map((plan, i) => (
            <Plan plan={plan} key={plan.id} isPopular={i === 1} onSubscribe={onSubscribe} />
          ))
        }
    </div>

    <div className="row">
      <div className="callout callout-info">
        Enjoy the flexibility of a pay-as-you-go model. Payment will be billed at the end
        of each subscription period based on the number of documents printed
        during that period. If you decide to cancel your subscription, your plan will remain
        active until the end of the current period. Upon the conclusion of this period,
        your final payment, based on your usage, will be processed.
      </div>
    </div>
  </>
);

YearlyPlans.propTypes = {
  plans: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    period: PropTypes.string.isRequired,
    prints_included: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
    price_per_print: PropTypes.number.isRequired,
  })).isRequired,
  onSubscribe: PropTypes.func,
};

YearlyPlans.defaultProps = {
  onSubscribe: null,
};

export default YearlyPlans;
