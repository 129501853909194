import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { Alert } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';

import Loader from '../Components/Loader';
import { reducer, sendRequest, useAuth } from '../utils';

const { REACT_APP_RECAPTCHA_SITE_KEY } = process.env;

const ForgotPassword = () => {
  const [logged] = useAuth();
  const recaptchaRef = React.createRef();

  const [email, setEmail] = React.useState('');
  const [state, dispatch] = React.useReducer(reducer, { message: '', error: '' });

  if (logged) {
    return <Redirect to="/" />;
  }

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch({ type: 'FETCH_INIT' });

    // Get the recaptcha token
    const token = recaptchaRef.current.getValue();
    if (!token) {
      dispatch({ type: 'FETCH_FAILURE', error: 'Please verify you are not a robot.' });
      return;
    }

    const opts = {
      email,
      recaptcha_token: token,
    };

    sendRequest('auth/restore', 'POST', opts)
      .then((response) => {
        if (response.status_code === 200) {
          dispatch({ type: 'FETCH_SUCCESS', message: response.message });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      });
  };

  return (
    <div className="row justify-content-md-center">
      <div className="col-md-6">
        <h2 className="mb-4">Restore Password</h2>

        {
          state.isLoading
            ? <Loader />
            : (
              <>
                {
                  state.isError
                    ? <Alert variant="danger">{state.error}</Alert>
                    : ''
                }

                {
                  state.message
                    ? <Alert variant="success">{state.message}</Alert>
                    : ''
                }

                <form onSubmit={onSubmit}>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email address
                      <input
                        id="email"
                        type="email"
                        className="form-control"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        required
                      />
                    </label>
                  </div>

                  <div className="mb-3">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={REACT_APP_RECAPTCHA_SITE_KEY}
                    />
                  </div>

                  <button type="submit" className="btn btn-warning">Confirm</button>
                </form>
              </>
            )
        }
      </div>
    </div>
  );
};

export default ForgotPassword;
