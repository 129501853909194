import React from 'react';

const Service = () => (
  <div className="container">
    <div className="row">
      <div className="col-xl-10 offset-xl-1">
        <div className="row mb-4">
          <h1 className="d-block mt-4 mb-4 text-warning">Get Expert Help with Product Installation and Configuration</h1>

          <p>
            Setting up document handling, installation, and printer configuration can take up
            valuable time that you could spend on your business. That&apos;s why we&apos;re here
            to help. Our team at VentorTech is ready to step in and provide you with the assistance
            you need. We&apos;ll schedule the earliest available day to join your project,
            ensuring the installation and configuration of the app are tailored
            to your business requirements.
          </p>
        </div>

        <div className="row mb-4">
          <h2 className="text-warning mb-3">How It Works</h2>

          <ol>
            <li className="mb-3">
              Request the Service: Use the button below to request installation services
              from the VentorTech team. This will take you to our Stripe checkout page
              for easy payment.

              <br />
              <br />

              <a href="https://buy.stripe.com/cN2dRUeP9dTx3ug4h0" className="btn btn-warning" target="_blank" rel="noreferrer">Request Installation Service</a>
            </li>

            <li className="mb-3">
              After Payment: Once you&apos;ve completed the payment, please contact us
              through our support portal at
              {' '}
              <a href="https://support.ventor.tech/">https://support.ventor.tech/</a>
              {' '}
              with the necessary
              details to get started:

              <ul className="mt-2">
                <li>
                  <strong>GitHub Repository Access:</strong>
                  {' '}
                  Please provide access to your GitHub repository.
                  Our user is
                  {' '}
                  <i>ventor-dev</i>
                  .
                </li>
                <li>
                  <strong>Odoo.sh Access:</strong>
                  {' '}
                  If you&apos;re using Odoo.sh, grant access to our user
                  {' '}
                  <i>ventor-dev</i>
                  .
                </li>
                <li>
                  <strong>Meeting Preferences:</strong>
                  {' '}
                  Let us know your preferred dates and times for the meeting.
                </li>
                <li>
                  <strong>Specific Requirements:</strong>
                  {' '}
                  Share any specific requirements you have so
                  we can prepare accordingly.
                </li>
              </ul>
            </li>
          </ol>
        </div>

        <div className="row mb-4">
          <h2 className="text-warning mb-3">Important Notes</h2>

          <ul>
            <li>
              <strong>No Customizations Included:</strong>
              {' '}
              Please note that this service package does not include
              custom feature additions or customizations to the current version of the product.
              We&apos;ll focus on ensuring the product is installed and configured for
              optimal performance based on existing features.
            </li>
            <li>
              <strong>Direct Print PRO Module and Subscription Requirement:</strong>
              {' '}
              Ensure you already have the Direct Print PRO module and either
              an active subscription or are subscribed to our
              {' '}
              <a href="https://ecosystem.ventor.tech/" target="_blank" rel="noreferrer">Ecosystem subscription</a>
              {' '}
              before
              purchasing this service package.
            </li>
          </ul>
        </div>

        <div className="row mb-4">
          <h2 className="text-warning mb-3">Need Custom Solutions?</h2>
          <p>
            While this package focuses on installation and setup, we understand the importance
            of tailoring our services to meet your unique business needs. For custom solutions
            or further assistance, don&apos;t hesitate to contact us:

            <a href="https://support.ventor.tech/" className="btn btn-outline-warning mt-3">Contact Us</a>
          </p>
        </div>

      </div>
    </div>
  </div>

);

export default Service;
