import React from 'react';
import PropTypes from 'prop-types';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { Alert, Col, Form } from 'react-bootstrap';
import Stripe from './Stripe';
import {
  getCurrentUser, reducer, round, sendRequest,
} from '../utils';
import Loader from '../Components/Loader';
import CountryField from '../Components/CountryField';
import PaymentMethodField from '../Components/PaymentMethodField';

const CheckoutForm = ({ plan, handleCancel, handleSuccessSubscription }) => {
  const elements = useElements();
  const stripe = useStripe();

  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [countryCode, setCountryCode] = React.useState('');
  const [paymentMethodId, setPaymentMethodId] = React.useState('');
  const [isCompany, setIsCompany] = React.useState(false);
  const [company, setCompany] = React.useState('');
  const [vatNumber, setVatNumber] = React.useState('');

  const [vat, setVat] = React.useState(0);

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: {}, message: '', error: '', isLoading: false, isError: false,
    },
  );

  React.useEffect(() => {
    getCurrentUser()
      .then((user) => {
        if (user.first_name && user.last_name) {
          setFirstName(user.first_name);
          setLastName(user.last_name);
        }
        setEmail(user.email);
        setVatNumber(user.vat || '');
        setCountryCode(user.country);
        setPaymentMethodId(user.default_payment_method?.stripe_id);
        setIsCompany(user.is_company);
        setCompany(user.company || '');
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch({ type: 'FETCH_INIT' });

    // const opts = Object.fromEntries(new FormData(e.target));

    if (!stripe || !elements) {
      dispatch({ type: 'FETCH_FAILURE', error: 'Something wrong with Stripe. Try again later' });
      // Stripe.js has not loaded yet
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const connector = new Stripe({
      stripe,
      card: cardElement,
      onError: (error) => {
        if (typeof error === 'object') {
          dispatch({ type: 'FETCH_FAILURE', error: error.message });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error });
        }
      },
      onSuccess: (result) => {
        // TODO: Implement logic?
        handleSuccessSubscription(result);

        dispatch({ type: 'FETCH_SUCCESS', message: result });
      },
    });

    // Update user profile
    const opts = {
      first_name: firstName,
      last_name: lastName,
      company,
      country: countryCode,
      vat: vatNumber,
      is_company: isCompany,
    };

    sendRequest('accounts', 'PUT', opts)
      .then((response) => {
        if (response.status_code === 200) {
          connector.createPaymentMethodAndSubscribe({
            paymentMethodId,
            planId: plan.id,
            billingDetails: {
              email,
              name: isCompany ? company : `${firstName} ${lastName}`,
            },
            countryCode,
            vatNumber: isCompany ? vatNumber : null,
          });
        } else {
          dispatch({ type: 'FETCH_FAILURE', message: response.message });
        }
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error });
      });
  };

  const calculateTotalLater = () => round(plan.price * (1 + vat / 100), 2);
  const calculateVat = () => round(plan.price * (vat / 100), 2);

  const updateTaxRate = () => {
    dispatch({ type: 'FETCH_INIT' });

    const opts = {
      country_code: countryCode,
    };

    if (isCompany && vatNumber) {
      opts.vat_number = vatNumber;
    }

    sendRequest('tax-rates', 'GET', opts)
      .then((response) => {
        if (response.status_code !== 200) {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        } else {
          if (response.data.length) {
            const taxRate = response.data[0];
            setVat(taxRate.rate);
          } else {
            setVat(0.0);
          }
          dispatch({ type: 'FETCH_SUCCESS' });
        }
      });
  };

  React.useEffect(() => {
    if (countryCode) {
      updateTaxRate();
    }
  }, [countryCode, isCompany, vatNumber]);

  return (
    <div className="card mt-4">
      <div className="card-body">
        <p><strong>Enter your card details. Your subscription will start now.</strong></p>

        <div className="row">
          <div className="col-md-7">
            <Form id="payment-form" onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="6">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    id="first_name"
                    name="first_name"
                    type="text"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">First Name can&apos;t be empty</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    id="last_name"
                    name="last_name"
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">Last Name can&apos;t be empty</Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Email can&apos;t be empty</Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <CountryField
                  label="Country"
                  name="country_code"
                  defaultCountry={countryCode}
                  onChange={(newCountryCode) => setCountryCode(newCountryCode)}
                  required
                />
              </Form.Group>

              <hr className="mt-4" />

              <Form.Group>
                <Form.Check
                  type="checkbox"
                  id="is_company"
                  name="is_company"
                  label="Paying from the Company?"
                  checked={isCompany}
                  onChange={() => setIsCompany(!isCompany)}
                />

              </Form.Group>

              {
                isCompany
                && (
                <>
                  <Form.Group>
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control
                      id="company"
                      name="company"
                      type="text"
                      placeholder="Company Name"
                      onChange={(e) => setCompany(e.target.value)}
                      value={company}
                      required
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid" />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>EU VAT Number</Form.Label>
                    <Form.Control
                      id="vat"
                      name="vat"
                      type="text"
                      placeholder="Enter EU VAT Number (not required)"
                      onChange={(e) => setVatNumber(e.target.value)}
                      value={vatNumber}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid" />
                  </Form.Group>
                </>
                )
              }
              <hr className="mt-4" />

              <Form.Group>
                <PaymentMethodField
                  label="Select pre-saved credit/debit card"
                  name="payment_method"
                  defaultPaymentMethod={paymentMethodId}
                  onChange={(newPaymentMethodId) => setPaymentMethodId(newPaymentMethodId)}
                />
              </Form.Group>

              {
                !paymentMethodId
                && (
                  <Form.Group>
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <Form.Label>
                      Or pay with a new card
                    </Form.Label>
                    <CardElement
                      className="form-control"
                      options={{
                        style: {
                          base: {
                            fontSize: '16px',
                            color: '#32325d',
                            fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif',
                            fontSmoothing: 'antialiased',
                            '::placeholder': {
                              color: '#a0aec0',
                            },
                          },
                          invalid: {
                            color: '#9e2146',
                          },
                        },
                      }}
                    />
                    <p><small>This is a secure SSL encrypted payment</small></p>
                  </Form.Group>
                )
              }
              {
                !state.isLoading
                  && (
                    <>
                      <button type="submit" className="btn btn-primary mr-4" disabled={!stripe}>Subscribe</button>
                      <button type="button" className="btn btn-outline-secondary" onClick={handleCancel}>Cancel</button>
                      {/* <p className="mt-2 mb-0 text-muted">
                        By clicking the subscribe button, you agree to our
                        {' '}
                        <a href="/privacy-policy">Privacy Policy</a>
                      </p> */}
                    </>
                  )
              }
            </Form>
          </div>
          <div className="col-md-5">
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th>Product</th>
                  <td>
                    <strong className="d-block mb-1">{plan.name}</strong>

                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <strong>Total due now:</strong>
                        <span className="float-right">€0.00</span>
                      </li>
                      <li className="list-group-item">
                        <strong>Total in a month:</strong>
                        <span className="float-right">
                          €
                          {plan.price}
                        </span>
                      </li>
                      <li className="list-group-item">
                        <strong>Total per print:</strong>
                        {' '}
                        <span className="float-right">
                          €
                          {plan.price_per_print}
                        </span>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th>VAT</th>
                  <td>
                    <span className="float-right">
                      {`€${calculateVat()} (${vat} %)`}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>Total now</th>
                  <th><span className="float-right">€0.00</span></th>
                </tr>
                <tr>
                  <th>Total later</th>
                  <th>
                    <span className="float-right">
                      {`From €${calculateTotalLater()}`}
                    </span>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {state.isLoading && <Loader />}
        {state.isError && <Alert className="mt-4" variant="danger">{state.error}</Alert>}
      </div>
    </div>
  );
};

CheckoutForm.propTypes = {
  plan: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    price_per_print: PropTypes.number.isRequired,
  }).isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSuccessSubscription: PropTypes.func.isRequired,
};

export default CheckoutForm;
