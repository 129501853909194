import React from 'react';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';

import { Alert } from 'react-bootstrap';
import { loadStripe } from '@stripe/stripe-js';
import { useHistory } from 'react-router';
import Stripe from '../Payments/Stripe';
import { getCurrentUser, reducer, sendRequest } from '../utils';
import Loader from '../Components/Loader';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const AddPaymentMethod = () => (
  <Elements stripe={stripePromise}>
    <AddPaymentMethodForm />
  </Elements>
);

const AddPaymentMethodForm = () => {
  const elements = useElements();
  const stripe = useStripe();
  const history = useHistory();

  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [isDefault, setIsDefault] = React.useState(false);

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: {}, message: '', error: '', isLoading: false, isError: false,
    },
  );

  React.useEffect(() => {
    getCurrentUser()
      .then((user) => {
        if (user.first_name && user.last_name) {
          setName(`${user.first_name} ${user.last_name}`);
        }
        setEmail(user.email);
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch({ type: 'FETCH_INIT' });

    if (!stripe || !elements) {
      dispatch({ type: 'FETCH_FAILURE', error: 'Something wrong with Stripe. Try again later' });
      // Stripe.js has not loaded yet
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const connector = new Stripe({
      stripe,
      card: cardElement,
      onError: (error) => {
        dispatch({ type: 'FETCH_FAILURE', error });
      },
      onSuccess: (result) => {
        sendRequest('payment-methods', 'POST', { payment_method_id: result.paymentMethod.id, is_default: isDefault })
          .then((response) => {
            if (response.status_code === 200) {
              history.push('/profile');
            } else {
              dispatch({ type: 'FETCH_FAILURE', error: response.message });
            }
          })
          .catch((error) => {
            dispatch({ type: 'FETCH_FAILURE', error });
          });
      },
    });

    connector.createPaymentMethod({
      billingDetails: {
        email,
        name,
      },
    });
  };

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <div className="card mt-4">
      <div className="card-body">
        <p>
          <strong>
            Enter your card details. We do not save any sensitive information on our servers.
          </strong>
        </p>

        <div className="row">
          <div className="col-md-12">
            <form id="payment-form" onSubmit={handleSubmit}>
              <div className="form-group mb-4">
                <label htmlFor="name">
                  Full Name
                  <input
                    id="name"
                    name="name"
                    type="text"
                    className="form-control"
                    placeholder="Enter name"
                    onChange={(e) => setName(e.target.value)}
                    defaultValue={name}
                    required
                  />
                </label>
              </div>

              <div className="form-group mb-4">
                <label htmlFor="email">
                  Email
                  <input
                    id="email"
                    name="email"
                    type="email"
                    className="form-control"
                    placeholder="Enter email"
                    onChange={(e) => setEmail(e.target.value)}
                    defaultValue={email}
                    required
                  />
                </label>
              </div>

              <div className="form-group mb-4">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label>
                  Card
                  <CardElement
                    className="form-control"
                    options={{
                      style: {
                        base: {
                          fontSize: '16px',
                          color: '#32325d',
                          fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif',
                          fontSmoothing: 'antialiased',
                          '::placeholder': {
                            color: '#a0aec0',
                          },
                        },
                        invalid: {
                          color: '#9e2146',
                        },
                      },
                    }}
                  />
                </label>
              </div>

              <div className="form-group mb-4">
                <div className="form-check">
                  <label className="form-check-label" htmlFor="is_default">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      defaultChecked={isDefault}
                      onChange={(e) => { setIsDefault(e.target.checked); }}
                      id="is_default"
                      name="is_default"
                    />
                    Make default payment method
                  </label>
                </div>
              </div>

              <button type="submit" className="btn btn-primary mr-3 mb-3" disabled={!stripe}>Save Payment Method</button>
              <button type="button" className="btn btn-outline-secondary mb-3" onClick={handleCancel}>Cancel</button>
            </form>
          </div>
        </div>

        {state.isLoading && <Loader />}
        {state.isError && <Alert className="mt-4" variant="danger">{state.error}</Alert>}
      </div>
    </div>
  );
};

export default AddPaymentMethod;
