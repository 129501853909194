import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Card } from 'react-bootstrap';
import { getCurrentUser, reducer } from '../utils';
import Loader from '../Components/Loader';
import Subscription from './Subscription';
import UsagePlot from './UsagePlot';
import Applications from './Applications';
import Payments from './Payments';

const Profile = () => {
  const [state, dispatch] = React.useReducer(
    reducer,
    { data: {}, isLoading: true, isError: false },
  );

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });
    getCurrentUser()
      .then((user) => {
        dispatch({ type: 'FETCH_SUCCESS', payload: user });
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', payload: error });
      });
  }, []);

  if (state.isError) {
    return <p>Something went wrong ...</p>;
  }

  if (state.isLoading) {
    return <Loader />;
  }

  return (
    <div className="row">
      <div className="col-md-12 col-lg-6 col-xl-5 d-flex"><CommonInfo user={state.data} /></div>
      <div className="col-md-12 col-lg-6 col-xl-7 d-flex"><UsagePlot /></div>
      <div className="col-md-12 d-flex"><Subscription /></div>
      <div className="col-md-12 d-flex"><Payments /></div>
      <div className="col-md-12 d-flex"><Applications /></div>
    </div>
  );
};

const CommonInfo = ({ user }) => (
  <>
    {
      !user
        ? (
          <Alert variant="danger">
            Cannot fetch user profile
          </Alert>
        )
        : (
          <Card className="mb-4 flex-fill">
            <Card.Body>
              <Card.Title>Information</Card.Title>
              <dl className="row mt-4">
                <dt className="col-sm-3">Email</dt>
                <dd className="col-sm-9">{user.email}</dd>

                <dt className="col-sm-3">Name</dt>
                <dd className="col-sm-9">
                  {`${user.first_name || ''} ${user.last_name || ''}`}
                </dd>

                {
                user.is_company
                  && (
                  <>
                    <dt className="col-sm-3">Company</dt>
                    <dd className="col-sm-9">{user.company}</dd>
                  </>
                  )
                }

                <dt className="col-sm-3">Phone</dt>
                <dd className="col-sm-9">{user.phone || ' - '}</dd>

                <dt className="col-sm-3">City / Country</dt>
                <dd className="col-sm-9">
                  {`${user.city || '-'} / ${user.country || '-'}`}
                </dd>

              </dl>
              <Button href="/profile/edit" variant="outline-warning">Edit</Button>
            </Card.Body>
          </Card>
        )
    }
  </>
);

CommonInfo.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    is_company: PropTypes.bool,
    company: PropTypes.string,
    phone: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
};

export default Profile;
