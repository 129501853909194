import React from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import Loader from '../Components/Loader';
import { ls, reducer, sendRequest } from '../utils';
import SubscriptionAccount from '../Account/SubscriptionAccount';

const GettingStartedStep2 = () => {
  const history = useHistory();
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: null, error: '', message: '', isError: false, isLoading: true,
    },
  );

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('subscriptions', 'GET', { active_only: true })
      .then((response) => {
        if (response.status_code === 200) {
          const subscription = response.data[0];

          if (!subscription) {
            history.push('/plans');
          }

          let redirectURL = ls.pop('REDIRECT_URL');

          if (redirectURL) {
            const apiKey = subscription?.printnode_account?.api_key;

            redirectURL = new URL(redirectURL);
            redirectURL.searchParams.set('api_key', apiKey.key);
          }
          dispatch({
            type: 'FETCH_SUCCESS',
            payload: { subscription, redirectURL: redirectURL && redirectURL.toString() },
          });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.error });
        }
      });
  }, []);

  if (state.isLoading) {
    return (<Loader />);
  }

  return (
    <div>
      <h4 className="mb-4">Step 2. Now you need to login to desktop client and enter API key in Odoo</h4>

      <SubscriptionAccount account={state.data.subscription.printnode_account} />

      <p>
        Or you can always find it in your account.
      </p>
      <p>
        <strong>
          Then you need to go back to your Odoo (
          <Link to="/docs" target="_blank">See documentation</Link>
          )
        </strong>
      </p>

      {
        state.data?.redirectURL
        && (
        <>
          <hr />
          <p className="mb-0"><a href={state.data.redirectURL} className="btn btn-outline-primary ml-2">Go back to Odoo</a></p>
        </>
        )
      }

      <hr />

      <p className="mb-0">
        <Link to="/profile" className="btn btn-outline-primary mr-3">Go to Profile</Link>
        <Link to="/getting-started-1" className="btn btn-outline-secondary">Go Back to Step 1</Link>
      </p>
    </div>
  );
};

export default GettingStartedStep2;
