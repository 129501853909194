import React from 'react';
import { Redirect } from 'react-router-dom';
import {
  Alert, Button, Col, Form, Row,
} from 'react-bootstrap';
import { login, reducer, sendRequest, useAuth } from '../utils';

const Restore = () => {
  const [logged] = useAuth();
  const [validated, setValidated] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [passwordConfirmation, setPasswordConfirmation] = React.useState('');

  const [state, dispatch] = React.useReducer(reducer, { message: '', error: '' });

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      return;
    }

    const opts = {
      token,
      password,
    };

    sendRequest('auth/reset', 'POST', opts)
      .then((response) => {
        if (response.status_code === 200) {
          login(response.data);
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      })
      .catch((error) => dispatch({ type: 'FETCH_FAILURE', payload: error }));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordConfirmation('');

    if (e.target.value.length < 6) {
      e.target.setCustomValidity(false);
    } else {
      e.target.setCustomValidity('');
    }
    setValidated(true);
  };

  const handlePasswordConfirmationChange = (e) => {
    setPasswordConfirmation(e.target.value);

    if (e.target.value !== password) {
      e.target.setCustomValidity(false);
    } else {
      e.target.setCustomValidity('');
    }
    setValidated(true);
  };

  if (logged) {
    return <Redirect to="/" />;
  }

  return (
    <Row className="row justify-content-md-center">
      <Col md="6">
        <h2 className="mb-4">Restore Password</h2>
        {
            state.error
              ? <Alert variant="danger">{state.error}</Alert>
              : ''
        }

        {
            state.message
              ? <Alert variant="success">{state.message}</Alert>
              : ''
        }

        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>New Password</Form.Label>
            <Form.Control
              required
              id="password"
              type="password"
              placeholder="New Password"
              value={password}
              onChange={handlePasswordChange}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Password should be min 6 symbols</Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>New Password Confirmation</Form.Label>
            <Form.Control
              required
              id="confirm_password"
              type="password"
              placeholder="New Password Confirmation"
              value={passwordConfirmation}
              onChange={handlePasswordConfirmationChange}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Passwords don&apos;t match</Form.Control.Feedback>
          </Form.Group>

          <Alert variant="info">
            Password Requirements:
            <ul className="small mb-0">
              <li>Minimum 8 characters.</li>
              <li>The alphabets must be between [a-z]</li>
              <li>At least one alphabet should be of Upper Case [A-Z]</li>
              <li>At least 1 number or digit between [0-9].</li>
              <li>At least 1 character from [like _ ,  @ $ or other special symbol].</li>
            </ul>
          </Alert>

          <Button type="submit">Save</Button>
        </Form>
      </Col>
    </Row>
  );
};

export default Restore;
