import React from 'react';
import { Card } from 'react-bootstrap';
import Invoices from './Invoices';
import PaymentMethods from './PaymentMethods';

const Payments = () => (
  <Card className="mb-4 flex-fill">
    <Card.Body>
      <Card.Title>Payments</Card.Title>
      <Invoices />

      <PaymentMethods />
    </Card.Body>
  </Card>
);

export default Payments;
