import React from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import Applications from '../Account/Applications';

const GettingStartedStep1 = () => {
  const history = useHistory();

  const downloadAction = () => {
    setTimeout(() => history.push('/getting-started-2'), 1000);
  };

  return (
    <div>
      <h3 className="mt-4 mb-4">Thank you for your subscription!</h3>

      <h4>Step 1. Now you need to install our desktop client</h4>

      <Applications downloadAction={downloadAction} />

      <small>
        <Link to="/docs" target="_blank">See documentation</Link>
      </small>

      <hr />

      <p className="mb-0">
        <Link to="/getting-started-2" className="btn btn-outline-primary">Continue to Step 2</Link>
      </p>
    </div>
  );
};

export default GettingStartedStep1;
