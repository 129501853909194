import React from 'react';

const HowToLeaveReview = () => (
  <div className="container">
    <h1 className="mb-4">How to Leave a Review</h1>
    <img src="/images/leave-review-manual.png" className="w-100" alt="How to Review" />
  </div>
);

export default HowToLeaveReview;
