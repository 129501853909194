import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ContactUs = ({ btnClass, btnVariant }) => {
  // Impossible to change styles into css files
  window.addEventListener('DOMContentLoaded', () => {
    // gets the iframe on which the widget is embedded
    const iframe = document.querySelector('#jsd-widget');
    iframe.style.bottom = '50px';
    iframe.style.right = '5px';
  });

  // If internet very slow, iframe might not load widget and click will raise Error
  const onClick = () => {
    // gets the iframe on which the widget is embedded
    const iframe = document.querySelector('#jsd-widget');
    // gets the content of the iframe
    const iframeContent = iframe.contentDocument;

    const widgetBtn = iframeContent.querySelector('#help-button');

    if (!widgetBtn) {
      // eslint-disable-next-line no-alert
      alert('Something went wrong, please contact us via email: support@ventor.tech');
      return;
    }

    widgetBtn.click();
  };

  return (
    <Button
      variant={btnVariant || 'outline-warning'}
      className={btnClass || 'pl-3 pr-3 mb-2 mb-md-0 mr-md-2 ml-md-2'}
      onClick={onClick}
    >
      Contact Us
    </Button>
  );
};

ContactUs.propTypes = {
  btnClass: PropTypes.string,
  btnVariant: PropTypes.string,
};

ContactUs.defaultProps = {
  btnClass: null,
  btnVariant: null,
};

export default ContactUs;
