import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import Loader from '../Components/Loader';
import InputWithCopy from '../Components/InputWithCopy';
import { ls, reducer, sendRequest, useAuth } from '../utils';

const GetAPIKey = () => {
  const [logged] = useAuth();
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: null, error: '', message: '', isError: false, isLoading: false,
    },
  );

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    if (logged) {
      sendRequest('subscriptions', 'GET', { active_only: true })
        .then((response) => {
          if (response.status_code === 200) {
            const subscriptions = response.data;
            // Assume that first of subscription will be required
            // TODO: Of course this will not work with multiple subscription for different products
            const subscription = subscriptions[0];

            // No active subscription
            if (!subscription) {
              window.location = '/plans';
            }

            let redirectURL = ls.pop('REDIRECT_URL');

            const apiKey = subscription?.printnode_account.api_key;

            if (redirectURL) {
              redirectURL = new URL(redirectURL);
              redirectURL.searchParams.set('api_key', apiKey.key);

              // Hard redirect to the source location
              setTimeout(() => { window.location = redirectURL; }, 1000);
            }
            dispatch({
              type: 'FETCH_SUCCESS',
              payload: {
                key: apiKey,
                redirectURL: redirectURL && redirectURL.toString(),
              },
            });
          } else {
            // No subscription
            dispatch({ type: 'FETCH_FAILURE', error: response.message });

            // Hard redirect to the source location
            window.location = '/plans';
          //   setTimeout(() => { window.location = '/plans'; }, 1000);
          }
        });
    }
  }, []);

  if (!logged) {
    return <Redirect to="/register" />;
  }

  if (state.isLoading) {
    return <Loader />;
  }

  if (state.isError) {
    return (
      <Alert variant="danger">
        <Alert.Heading>Something went wrong</Alert.Heading>
        {state.error}

        <div className="text-center small pt-4">
          <div className="mb-2">You will be redirected to Plans page in few seconds...</div>
          <a href="/plans" className="btn btn-sm btn-outline-primary">Redirect Now</a>
        </div>
      </Alert>
    );
  }

  return (
    <Alert variant="success">
      <Alert.Heading>API Key</Alert.Heading>
      <p>Please, copy this API Key and insert in the app</p>
      <hr />
      <InputWithCopy value={state.data?.key?.key} readonly />

      {
        state.data?.redirectURL
          ? (
            <div className="text-center small pt-4">
              <div className="mb-2">If you came from external app you will be redirected in few seconds...</div>
              <Button
                href={state.data?.redirectURL}
                size="sm"
                variant="outline-primary"
              >
                Redirect Now
              </Button>
            </div>
          )
          : (
            <>
              <hr />
              <p className="mb-0">
                <Link to="/profile" className="btn btn-outline-primary">Go to profile</Link>
                {state.data?.redirectURL && <a href={state.data.redirectURL} className="btn btn-outline-secondary ml-2">Go back to Odoo</a>}
              </p>
            </>
          )
      }
    </Alert>
  );
};

export default GetAPIKey;
