import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    function handleScroll() {
      setIsVisible(window.pageYOffset > 300);
    }

    document.addEventListener('scroll', handleScroll);
    return () => { document.removeEventListener('scroll', handleScroll); };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    isVisible && (
    <Button variant="warning" className="btn-floating btn-back-to-top text-white" onClick={scrollToTop}>
      <FontAwesomeIcon icon={['fas', 'arrow-up']} />
    </Button>
    )
  );
};

export default ScrollToTop;
