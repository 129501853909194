import PropTypes from 'prop-types';
import React from 'react';
import { reducer, sendRequest } from '../utils';

const CountryField = ({ label, name, defaultCountry, onChange, required, disabled }) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isLoading: true, isError: false,
    },
  );

  const handleCountryFetch = () => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('countries', 'GET')
      .then((response) => {
        dispatch({ type: 'FETCH_SUCCESS', payload: response.data });
      })
      .catch(() => {
        dispatch({
          type: 'FETCH_FAILURE',
          payload: 'Something wen wrong. Please, contact us: support@ventor.tech',
        });
      });
  };

  React.useEffect(() => {
    handleCountryFetch();
  }, []);

  const handleChange = (e) => {
    onChange(e.target.value);
  };
  return (
    <>
      <label className="form-label" htmlFor={name}>
        {label}
      </label>

      {
      state.isLoading
        ? <p className="align-middle">Loading countries list...</p>
        : (
          <select name={name} onChange={handleChange} defaultValue={defaultCountry} className="custom-select" required={required} disabled={disabled}>
            <option value="">Select country</option>
            {
              state.data.map((c) => <option key={c.code} value={c.code}>{c.name}</option>)
            }
          </select>
        )
      }
    </>
  );
};

CountryField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  defaultCountry: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

CountryField.defaultProps = {
  defaultCountry: null,
  onChange: () => {},
  required: false,
  disabled: false,
};

export default CountryField;
