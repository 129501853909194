import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, Redirect } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { login, reducer, sendRequest, useAuth } from '../utils';
import Loader from '../Components/Loader';

const { REACT_APP_RECAPTCHA_SITE_KEY } = process.env;

const Register = () => {
  const [logged] = useAuth();
  const recaptchaRef = React.createRef();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [passwordConfirmation, setPasswordConfirmation] = React.useState('');

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: {}, message: '', error: '', isLoading: false, isError: false,
    },
  );

  if (logged) {
    return <Redirect to="/profile" />;
  }

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch({ type: 'FETCH_INIT' });

    // Get the recaptcha token
    const token = recaptchaRef.current.getValue();
    if (!token) {
      dispatch({ type: 'FETCH_FAILURE', error: 'Please verify you are not a robot.' });
      return;
    }

    const opts = {
      email,
      password,
      password_confirmation: passwordConfirmation,
      recaptcha_token: token,
    };

    sendRequest('auth/register', 'POST', opts)
      .then((response) => {
        if (response.status_code === 200) {
          // Show message
          dispatch({ type: 'FETCH_SUCCESS', message: response.message });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      })
      .catch((err) => dispatch({ type: 'FETCH_FAILURE', error: err }));
  };

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handlePasswordConfirmationChange = (e) => setPasswordConfirmation(e.target.value);

  const handleSuccessGoogleLogin = (googleData) => {
    dispatch({ type: 'FETCH_INIT' });

    const opts = {
      google_token: googleData.credential,
    };

    sendRequest('auth/google', 'POST', opts)
      .then((response) => {
        if (response.status_code === 200) {
          login(response.data);
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      })
      .catch(() => {
        dispatch({
          type: 'FETCH_FAILURE',
          error: 'Our service is not available right now. Please contact us: support@ventor.tech',
        });
      });
  };

  const handleFailureGoogleLogin = (error) => {
    dispatch({
      type: 'FETCH_FAILURE',
      error: `Google login failed. ${error}`,
    });
  };

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <div className="row justify-content-md-center">
        <div className="col-md-6">
          <h2 className="mb-4">Register</h2>

          {state.isLoading
            ? <Loader />
            : (
              <>
                <p>
                  Have an account?&nbsp;
                  <Link className="text-warning" to="/login">Login</Link>
                </p>

                {state.message && <Alert variant="success">{state.message}</Alert>}
                {state.error && <Alert variant="danger">{state.error}</Alert>}

                <div className="mt-4 google-auth-button">
                  <GoogleLogin
                    onSuccess={handleSuccessGoogleLogin}
                    onError={handleFailureGoogleLogin}
                    text="continue_with"
                    size="large"
                  />
                </div>

                <div className="login-separator" />

                <form onSubmit={onSubmit}>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email address
                      <input
                        id="email"
                        type="email"
                        className="form-control"
                        onChange={handleEmailChange}
                        value={email}
                        required
                      />
                    </label>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Password
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        onChange={handlePasswordChange}
                        value={password}
                        required
                      />
                    </label>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="password_confirmation" className="form-label">
                      Password Confirmation
                      <input
                        type="password"
                        className="form-control"
                        id="password_confirmation"
                        onChange={handlePasswordConfirmationChange}
                        value={passwordConfirmation}
                        required
                      />
                    </label>
                  </div>

                  <Alert variant="warning">
                    Password Requirements:
                    <ul className="small mb-0">
                      <li>Minimum 8 characters.</li>
                      <li>The alphabets must be between [a-z]</li>
                      <li>At least one alphabet should be of Upper Case [A-Z]</li>
                      <li>At least 1 number or digit between [0-9].</li>
                      <li>At least 1 character from [like _ ,  @ $ or other special symbol].</li>
                    </ul>
                  </Alert>

                  <div className="mb-3">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={REACT_APP_RECAPTCHA_SITE_KEY}
                    />
                  </div>

                  <button type="submit" className="btn btn-warning">Register</button>
                </form>
              </>
            )}
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

export default Register;
