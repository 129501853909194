import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Card } from 'react-bootstrap';
import Loader from '../Components/Loader';
import { reducer, round, sendRequest } from '../utils';

const Applications = ({ downloadAction }) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: {}, message: '', error: '', isLoading: true, isError: false,
    },
  );

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('printnode-apps', 'GET')
      .then((response) => {
        if (response.status_code !== 200) {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
          return;
        }

        dispatch({ type: 'FETCH_SUCCESS', payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error });
      });
  }, []);

  if (state.isLoading) {
    return <Loader />;
  }

  return (
    <Card className="mb-4 flex-fill">
      <Card.Body>
        <Card.Title>Apps</Card.Title>

        {state.data.map((a) => <Application key={a.id} data={a} downloadAction={downloadAction} />)}

        <Alert variant="warning">
          Please contact us if you need an application for another operating system:
          {' '}
          <a className="text-warning" href="mailto:support@ventor.tech">support@ventor.tech</a>
        </Alert>
      </Card.Body>
    </Card>
  );
};

Applications.propTypes = {
  downloadAction: PropTypes.func,
};

Applications.defaultProps = {
  downloadAction: () => {},
};

const Application = ({ data, downloadAction }) => (
  <Card className="mb-4">
    <Card.Body>
      <div className="row">
        <div className="col-md-12">
          <h4 className="header-4">{data.filename}</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-md-10">
          <div>
            <strong>Operating Systems:</strong>
              &nbsp;
            {data.supported_os.join(', ')}
          </div>
          <div>
            <strong>Published:</strong>
              &nbsp;
            {data.date_updated}
          </div>
          <div>
            <strong>Size:</strong>
              &nbsp;
            {`${round(data.filesize / 1024 / 1024, 2)} MB`}
          </div>
          <div>
            <strong>Version:</strong>
              &nbsp;
            {data.version}
          </div>
        </div>

        <div className="col-md-2 text-right">
          <Button
            href={data.url}
            target="_blank"
            rel="noreferrer"
            variant="outline-warning"
            onClick={downloadAction}
          >
            <FontAwesomeIcon icon="download" />
          </Button>
        </div>
      </div>
    </Card.Body>
  </Card>
);

Application.propTypes = {
  data: PropTypes.shape({
    filename: PropTypes.string.isRequired,
    supported_os: PropTypes.arrayOf(PropTypes.string).isRequired,
    date_updated: PropTypes.string.isRequired,
    filesize: PropTypes.number.isRequired,
    version: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  downloadAction: PropTypes.func,
};

Application.defaultProps = {
  downloadAction: () => {},
};

export default Applications;
